// === rapportfinancierSlice.js ===
import { createSlice } from '@reduxjs/toolkit'
import { fetchDeconnexion } from '../connexion/connexionSlice'
import { API_URL } from '../../Constants'
import axios from 'axios'
// Import de date-fns pour le découpage mensuel
import { parse, lastDayOfMonth, format, addDays } from 'date-fns';

export const initialState = {
  loading: false,
  hasErrors: false,
  hasMore: true,
  loaded: false,
  donnee: true,
  finBandeControle: false,
  rapportfinancier: {},
  moisStatus: [],
};

const rapportfinancierSlice = createSlice({
  name: 'rapportfinancier',
  initialState,
  reducers: {
    // --- Actions existantes ---
    getRapportFinancier: (state) => {
      state.loading = true;
      state.loaded = false;
      state.donnee = null;
      state.hasErrors = false;
    },
    getRapportFinancierSuccess: (state, { payload }) => {
      state.rapportfinancier = payload;
      state.loading = false;
      state.hasErrors = false;
      state.loaded = true;
      state.donnee = true;
    },
    getBandeControleSuccess: (state, { payload }) => {
      state.rapportfinancier = payload;
      state.rapportfinancier.id_table = payload.id_table;
      state.loading = false;
      state.hasErrors = false;
      state.loaded = true;
      state.donnee = true;
    },
    getRapportFinancierFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.loaded = true;
      state.donnee = false;
    },
    getRapportFinancierAucuneDonnee: (state) => {
      state.loading = false;
      state.donnee = false;
      state.loaded = true;
      state.hasErrors = false;
    },
    getSuiteBandeControle: (state, { payload }) => {
      state.rapportfinancier.id_table = payload.id_table;
      state.rapportfinancier.sections = state.rapportfinancier.sections.concat(payload.sections);
      state.hasMore = true;
      state.finBandeControle = false;
    },
    setFinBandeControle: (state) => {
      state.hasMore = false;
      state.finBandeControle = true;
    },
    clearBandeControle: (state) => {
      if (!state.rapportfinancier.sections) {
        state.rapportfinancier.sections = [];
      } else {
        state.rapportfinancier.sections = [];
      }
      state.rapportfinancier.id_table = 0;
    },
    startPartialLoading: (state) => {
      state.loading = false;
      state.loaded = true;
      state.hasErrors = false;
      state.donnee = true;
    },

    // >>> Actions pour la barre de progression mensuelle
    setMoisStatus: (state, { payload }) => {
      // payload = tableau d'objets { label, date_min, date_max, status }
      state.moisStatus = payload;
    },
    updateMoisStatus: (state, { payload }) => {
      // payload = { index, status }
      const { index, status } = payload;
      if (state.moisStatus[index]) {
        state.moisStatus[index].status = status;
      }
    },
  },
});

export const {
  getRapportFinancier,
  getRapportFinancierSuccess,
  getBandeControleSuccess,
  getRapportFinancierFailure,
  getRapportFinancierAucuneDonnee,
  getSuiteBandeControle,
  setFinBandeControle,
  clearBandeControle,
  startPartialLoading,
  setMoisStatus,
  updateMoisStatus
} = rapportfinancierSlice.actions;

export const rapportfinancierSelector = (state) => state.rapportfinancier;

export default rapportfinancierSlice.reducer;

// --------------------------------------------------------------------------
// splitDateRangeByMonth(dateMin, dateMax) : découpe [dateMin, dateMax] en intervalles mensuels
// --------------------------------------------------------------------------
function splitDateRangeByMonth(dateMin, dateMax) {
  const start = parse(dateMin, 'yyyyMMdd', new Date());
  const end = parse(dateMax, 'yyyyMMdd', new Date());
  const intervals = [];
  let currentStart = start;

  while (currentStart <= end) {
    const currentMonthEnd = lastDayOfMonth(currentStart);
    const intervalEnd = currentMonthEnd > end ? end : currentMonthEnd;

    intervals.push({
      date_min: format(currentStart, 'yyyyMMdd'),
      date_max: format(intervalEnd, 'yyyyMMdd'),
    });

    currentStart = addDays(intervalEnd, 1);
  }
  return intervals;
}

// --------------------------------------------------------------------------
// fetchRapportFinancier : 
// - Vide le cache (clearBandeControle())
// - getRapportFinancier => "loading"
// - Si (cumul && rapport_id=5) => découpage mensuel en for + await
// - Sinon => logique d'origine (1 requête)
// --------------------------------------------------------------------------
export function fetchRapportFinancier(service) {
  return async (dispatch, getState) => {
    dispatch(clearBandeControle());
    dispatch(getRapportFinancier());

    const state = getState();
    const rapport = state.rapport.rapport;
    const cumul = state.rapports.cumul;
    const cumulData = state.cumul.cumul;
    const restaurant_choisi = state.connexion.restaurant_choisi;

    try {
      const config = {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      };

      // CAS 1 : cumul + rapport_id=5 => découpage mensuel
      if (cumul === true && rapport.rapport_id === 5) {
        const { date_min, date_max } = cumulData;
        const intervals = splitDateRangeByMonth(date_min, date_max);

        // >>> On initialise moisStatus avec tous les mois en "pending"
        // label = première lettre du mois, ou "M" + index, comme tu veux
        const moisStatusArray = intervals.map((interval) => {
          const parsedDate = parse(interval.date_min, 'yyyyMMdd', new Date());
          const label = format(parsedDate, 'LLLL')[0].toUpperCase(); // ex: "J" pour janvier
          return {
            label,
            date_min: interval.date_min,
            date_max: interval.date_max,
            status: 'pending', // on commence en "pending"
          };
        });
        dispatch(setMoisStatus(moisStatusArray));

        let anyDataFound = false;
        let firstPartial = false;

        for (let i = 0; i < intervals.length; i++) {
          const { date_min: minMois, date_max: maxMois } = intervals[i];

          try {
            // >>> On indique qu'on commence à charger le mois i
            dispatch(updateMoisStatus({ index: i, status: 'loading' }));

            const response = await axios.get(
              `${API_URL}/web/rapport/cumul/${rapport.rapport_id}/${minMois}/${maxMois}/0/${restaurant_choisi}`,
              config
            );
            const data = response.data;

            if (data.sections && data.sections.length > 0) {
              anyDataFound = true;

              if (!firstPartial) {
                dispatch(startPartialLoading());
                firstPartial = true;
              }

              dispatch(getSuiteBandeControle({
                sections: data.sections,
                id_table: data.id_table
              }));

              // >>> On indique que ce mois est "loaded" avec succès
              dispatch(updateMoisStatus({ index: i, status: 'loaded' }));
            } else {
              console.log(`Mois vide (sections=[]) : ${minMois} -> ${maxMois}`);
              // >>> S’il n’y a pas de données => on passe le mois en "no-data"
              dispatch(updateMoisStatus({ index: i, status: 'no-data' }));
            }
          } catch (err) {
            if (err.response && err.response.status === 404) {
              // Mois vide => on ignore
              // On passe le mois en "no-data"
              dispatch(updateMoisStatus({ index: i, status: 'no-data' }));
              continue;
            } else {
              throw err;  // Autre erreur => on arrête
            }
          }
        }

        if (!anyDataFound) {
          dispatch(getRapportFinancierAucuneDonnee());
        } else {
          dispatch(setFinBandeControle());
        }

      // CAS 2 : cumul + rapport_id != 5 => 1 seule requête
      } else if (cumul === true) {
        const { date_min, date_max } = cumulData;
        const response = await axios.get(
          `${API_URL}/web/rapport/cumul/${rapport.rapport_id}/${date_min}/${date_max}/0/${restaurant_choisi}`,
          config
        );
        const data = response.data;
        if (rapport.rapport_id === 5) {
          dispatch(getBandeControleSuccess(data));
        } else {
          dispatch(getRapportFinancierSuccess(data));
        }

      // CAS 3 : pas de cumul && rapport_id=5 => 1 seule requête
      } else if (!cumul && rapport.rapport_id === 5) {
        const response = await axios.get(
          `${API_URL}/web/rapport/${rapport.rapport_id}/${service.id_hist}/${service.num_tab_res}/0/${restaurant_choisi}/${service.id_service}`,
          config
        );
        const data = response.data;
        dispatch(getBandeControleSuccess(data));
        dispatch(setFinBandeControle());

      // CAS 4 : pas de cumul && rapport_id != 5 => 1 seule requête
      } else {
        const response = await axios.get(
          `${API_URL}/web/rapport/${rapport.rapport_id}/${service.id_hist}/${service.num_tab_res}/0/${restaurant_choisi}/${service.id_service}`,
          config
        );
        const data = response.data;
        dispatch(getRapportFinancierSuccess(data));
      }

    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          dispatch(fetchDeconnexion());
        } else if (error.response.status === 404) {
          dispatch(getRapportFinancierAucuneDonnee());
        } else {
          dispatch(getRapportFinancierFailure());
        }
      } else {
        dispatch(getRapportFinancierFailure());
      }
    }
  };
}

// --------------------------------------------------------------------------
// fetchBandeControle : inchangé
// (vous pouvez le commenter si vous ne voulez plus recharger en continu)
// --------------------------------------------------------------------------
export function fetchBandeControle(service) {
  return async (dispatch, getState) => {
    const state = getState();
    const rapport = state.rapport.rapport;
    const rapports_cumul = state.rapports.cumul;
    const cumul = state.cumul.cumul;
    const rapportfinancier = state.rapportfinancier.rapportfinancier;
    const restaurant_choisi = state.connexion.restaurant_choisi;

    try {
      const config = {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      };

      if (rapports_cumul === true) {
        const response = await axios.get(
          API_URL + '/web/rapport/cumul/' + rapport.rapport_id + '/' + cumul.date_min + '/' + cumul.date_max + '/' + rapportfinancier.id_table + '/' + restaurant_choisi,
          config
        );
        const data = await response.data;
        dispatch(getSuiteBandeControle(data));
      } else {
        const response = await axios.get(
          API_URL + '/web/rapport/' + rapport.rapport_id + '/' + service.id_hist + '/' + service.num_tab_res + '/' + rapportfinancier.id_table + '/' + "restaurant_choisi" + '/' + service.id_service,
          config
        );
        const data = await response.data;
        dispatch(getSuiteBandeControle(data));
      }

      if (rapportfinancier.sections.length >= rapportfinancier.taille) {
        dispatch(setFinBandeControle())
      }
    } catch (error) {
      if (error['response'] !== undefined) {
        if (error.response.status === 401) {
          dispatch(fetchDeconnexion())
        } else if (error.response.status == 404) {
          dispatch(getRapportFinancierAucuneDonnee())
        } else {
          dispatch(getRapportFinancierFailure())
        }
      } else {
        dispatch(getRapportFinancierFailure())
      }
    }
  }
}
