import React, { useEffect, useState, useRef } from 'react';
import history from '../../history';
import { useSelector, useDispatch } from 'react-redux';
import { fetchServices } from './servicesSlice';
import { getSelectedService } from '../service/serviceSlice';
import { unsetRapportCumul } from '../rapports/rapportsSlice';
import Button from 'react-bootstrap/Button';
import styles from './Services.module.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import 'moment/locale/fr';
import 'bootstrap/dist/css/bootstrap.min.css';

const localizer = momentLocalizer(moment);
const messages = {
  allDay: 'Journée',
  previous: 'Précédent',
  next: 'Suivant',
  today: 'Aujourd\'hui',
  month: 'mois',
  week: 'semaine',
  day: 'jour',
  agenda: 'Agenda',
  date: 'date',
  time: 'heure',
  event: 'événement',
  showMore: total => `+ ${total} événement(s) supplémentaire(s)`
};

export const Services = () => {
  const dispatch = useDispatch();
  const services = useSelector(state => state.services);
  const restaurant_choisi = useSelector(state => state.connexion.restaurant_choisi);

  // Détection de l'écran mobile (<680px)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 680);
  // Stocker le mois affiché en objet Moment
  const [viewDate, setViewDate] = useState(moment());
  // Stocker le jour sélectionné sous forme de string "YYYY-MM-DD"
  const [focusedDayKey, setFocusedDayKey] = useState(moment().format('YYYY-MM-DD'));
  // Hauteur dynamique du spacer (pour centrer la dernière ligne)
  const [spacerHeight, setSpacerHeight] = useState(0);

  // Références pour la barre horizontale (jours)
  const dayRefs = useRef({});
  // Références pour chaque ligne du tableau
  const rowRefs = useRef({});
  // Référence sur la dernière ligne
  const lastRowRef = useRef(null);
  // Référence sur le conteneur du tableau
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 680);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Charger les services
  useEffect(() => {
    dispatch(fetchServices());
  }, [restaurant_choisi, dispatch]);

  // Navigation vers la page de service
  const changementdepage = () => {
    history.push("/service/rapports");
  };

  // Fonction commune pour naviguer vers un service
  const OuvrirRapport = (event) => {
    dispatch(
      getSelectedService(
        services.services.filter(service => service.num_tab_res === event.id)
      )
    );
    dispatch(unsetRapportCumul());
    changementdepage();
  };

  // Boutons du header mobile
  const handleToday = () => {
    const todayKey = moment().format('YYYY-MM-DD');
    setViewDate(moment());
    setFocusedDayKey(todayKey);
    if (rowRefs.current[todayKey]) {
      rowRefs.current[todayKey].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handlePrevious = () => {
    const newViewDate = viewDate.clone().subtract(1, 'month');
    setViewDate(newViewDate);
    const firstDayKey = newViewDate.clone().date(1).format('YYYY-MM-DD');
    setFocusedDayKey(firstDayKey);
    if (rowRefs.current[firstDayKey]) {
      rowRefs.current[firstDayKey].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleNext = () => {
    if (!viewDate.isSame(moment(), 'month')) {
      const newViewDate = viewDate.clone().add(1, 'month');
      setViewDate(newViewDate);
      const firstDayKey = newViewDate.clone().date(1).format('YYYY-MM-DD');
      setFocusedDayKey(firstDayKey);
      if (rowRefs.current[firstDayKey]) {
        rowRefs.current[firstDayKey].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  // Scroll horizontal sur le jour sélectionné (barre de jours)
  useEffect(() => {
    if (focusedDayKey && isMobile && services.loaded) {
      const ref = dayRefs.current[focusedDayKey];
      if (ref && ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    }
  }, [focusedDayKey, isMobile, services.loaded]);

  // Calcul de la hauteur du spacer pour centrer la dernière ligne du tableau
  useEffect(() => {
    if (isMobile && services.loaded && containerRef.current && lastRowRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const rowRect = lastRowRef.current.getBoundingClientRect();
      const spaceBelowRow = containerRect.bottom - rowRect.bottom;
      const rowCenterOffset = rowRect.height / 2;
      const needed = (containerRect.height / 2) - spaceBelowRow - rowCenterOffset;
      setSpacerHeight(needed > 0 ? needed : 0);
    }
  }, [isMobile, services.loaded, containerRef]);

  // Centrer la dernière ligne après ajout du spacer
  useEffect(() => {
    if (isMobile && services.loaded && lastRowRef.current && spacerHeight > 0) {
      setTimeout(() => {
        lastRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 50);
    }
  }, [spacerHeight, isMobile, services.loaded]);

  // --------------------------------------------------------------------------
  // Rendu Mobile
  // --------------------------------------------------------------------------
  const renderMobileCalendar = () => {
    const daysInMonth = viewDate.daysInMonth();
    const daysArray = [];
    for (let d = 1; d <= daysInMonth; d++) {
      daysArray.push(viewDate.clone().date(d));
    }

    // Regrouper les services par jour
    const servicesByDay = {};
    daysArray.forEach(day => {
      const key = day.format('YYYY-MM-DD');
      servicesByDay[key] = [];
    });
    services.services.forEach(service => {
      const serviceDate = moment(service.date_service);
      if (serviceDate.isSame(viewDate, 'month')) {
        const key = serviceDate.format('YYYY-MM-DD');
        servicesByDay[key].push(service);
      }
    });

    return (
      <div className={styles.mobileCalendar}>
        <div className={styles.mobileHeader}>
          <Button 
            className={styles.btnAujourdhui} 
            onClick={handleToday}
          >
            Aujourd'hui
          </Button>
          <Button variant="secondary" onClick={handlePrevious}>Précédent</Button>
          <Button
            variant="secondary"
            onClick={handleNext}
            disabled={viewDate.isSame(moment(), 'month')}
          >
            Suivant
          </Button>
        </div>
        <div className={styles.monthLabel}>
          <h3>{viewDate.format('MMMM YYYY')}</h3>
        </div>

        {/* Barre horizontale des jours */}
        <div className={styles.daysBox}>
          {daysArray.map(day => {
            const dayKey = day.format('YYYY-MM-DD');
            if (!dayRefs.current[dayKey]) {
              dayRefs.current[dayKey] = React.createRef();
            }
            const hasServices = servicesByDay[dayKey].length > 0;
            const isFocused = (dayKey === focusedDayKey);
            return (
              <div
                key={dayKey}
                ref={dayRefs.current[dayKey]}
                className={`
                  ${styles.dayItem}
                  ${hasServices ? styles.dayHasServices : ''}
                  ${isFocused ? styles.dayFocused : ''}
                `}
                onClick={() => {
                  setFocusedDayKey(dayKey);
                  if (rowRefs.current[dayKey]) {
                    rowRefs.current[dayKey].scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }
                }}
              >
                {day.format('D')}
              </div>
            );
          })}
        </div>

        {/* Conteneur du tableau (seul élément défilable verticalement) */}
        <div className={styles.tableContainer} ref={containerRef}>
          <table className={styles.servicesTable}>
            <thead>
              <tr>
                <th>Jour</th>
                <th>Services</th>
              </tr>
            </thead>
            <tbody>
              {daysArray.map((day, index) => {
                const dayKey = day.format('YYYY-MM-DD');
                const dayServices = servicesByDay[dayKey] || [];
                const isLast = (index === daysArray.length - 1);
                const isRowSelected = (dayKey === focusedDayKey);
                return (
                  <tr
                    key={dayKey}
                    ref={el => {
                      rowRefs.current[dayKey] = el;
                      if (isLast) {
                        lastRowRef.current = el;
                      }
                    }}
                    className={styles.dayRow}
                    style={{
                      transition: "transform 0.3s ease, background-color 0.3s ease",
                      backgroundColor: isRowSelected ? "#bfbfbf" : ""
                    }}
                  >
                    <td>{day.format('D MMM')}</td>
                    <td>
                      {dayServices.length > 0
                        ? dayServices.map(service => (
                            <div key={service.num_tab_res}>
                              <button
                                className={styles.serviceButton}
                                onClick={() => OuvrirRapport({ id: service.num_tab_res })}
                              >
                                Service n°{service.num_tab_res_pokky}
                              </button>
                            </div>
                          ))
                        : 'Aucun service'}
                    </td>
                  </tr>
                );
              })}
              {/* Spacer dynamique pour centrer la dernière ligne */}
              <tr className={styles.spacerRow} style={{ height: spacerHeight }}>
                <td colSpan="2" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  // --------------------------------------------------------------------------
  // Rendu Desktop avec react-big-calendar
  // --------------------------------------------------------------------------
  const renderDesktopCalendar = () => {
    if (services.loaded === true) {
      const myEventsList = services.services.map(service => ({
        id: service.num_tab_res,
        title: "Service n°" + service.num_tab_res_pokky,
        start: service.date_service,
        end: service.date_service,
        service: service
      }));
      return (
        <div className={styles.div_calendrier}>
          <Calendar
            messages={messages}
            views={["month"]}
            localizer={localizer}
            popup="true"
            events={myEventsList}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 600 }}
            onSelectEvent={event => OuvrirRapport(event)}
          />
        </div>
      );
    } else {
      return (
        <Spinner
          className={styles.spinner}
          variant="danger"
          animation="border"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }
  };

  return (
    <div>
      {isMobile ? renderMobileCalendar() : renderDesktopCalendar()}
    </div>
  );
};
