import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Cumul.module.css';
import { format } from 'date-fns';
import { fetchCumul } from './cumulSlice';
import { setRapportCumul } from '../rapports/rapportsSlice';

registerLocale('fr', fr);

export const Cumul = () => {
  const dispatch = useDispatch();

  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  if (today.getTime() === firstDayOfMonth.getTime()) {
    firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 1);
  }

  const [dateMin, setDateMin] = useState(firstDayOfMonth);
  const [dateMax, setDateMax] = useState(today);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (dateMin < dateMax && dateMax <= new Date()) {
      const cumul = {
        date_min: format(dateMin, 'yyyyMMdd'),
        date_max: format(dateMax, 'yyyyMMdd'),
        date_min_affichage: format(dateMin, 'EEEE dd MMMM yyyy', { locale: fr }),
        date_max_affichage: format(dateMax, 'EEEE dd MMMM yyyy', { locale: fr }),
      };

      dispatch(setRapportCumul());
      dispatch(fetchCumul(cumul));
    } else {
      alert('La date de début doit être antérieure à la date de fin.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.cumulForm}>
      <h2>Veuillez choisir un intervalle de dates</h2>
      <div className={styles.datePickersContainer}>
        <div className={styles.datePickerGroup}>
          <label htmlFor="date_min" className={styles.label_date}>
            Du :
          </label>
          <DatePicker
            id="date_min"
            selected={dateMin}
            onChange={(date) => setDateMin(date)}
            dateFormat="dd/MM/yyyy"
            locale="fr"
            popperPlacement="bottom"
            maxDate={new Date()}                // empêche de sélectionner une date future
            placeholderText="Date de début"
            className={styles.datePickerInput}  // pour customiser l’apparence
          />
        </div>
        <div className={styles.datePickerGroup}>
          <label htmlFor="date_max" className={styles.label_date}>
            Au :
          </label>
          <DatePicker
            id="date_max"
            selected={dateMax}
            onChange={(date) => setDateMax(date)}
            dateFormat="dd/MM/yyyy"
            locale="fr"
            popperPlacement="bottom"
            maxDate={new Date()}
            placeholderText="Date de fin"
            className={styles.datePickerInput}
          />
        </div>
      </div>
      <Button type="submit" variant="primary" className={styles.validateButton}>
        Valider
      </Button>
    </form>
  );
};
