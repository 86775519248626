//front
import React, { useEffect, useRef, useState } from 'react';
import {
  fetchRapportFinancier,
  rapportfinancierSelector,
  setFinBandeControle,
} from './rapportfinancierSlice';
import {
  parse,
  differenceInCalendarMonths,
  addDays,
  addMonths,
  format,
  startOfMonth,
} from 'date-fns';

import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { Rapport } from '../rapport/Rapport';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
//import format from "date-fns/format";
import fr from 'date-fns/locale/fr';
import Spinner from 'react-bootstrap/Spinner';
import styles from './RapportFinancier.module.css';
import Cookies from 'js-cookie';

import {
  Redirect,
  useHistory,
  useParams,
  withRouter
} from "react-router-dom";

export const RapportFinancier = () => {

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  // Sélecteurs Redux
  const service = useSelector(state => state.service.service);
  const cumul = useSelector(state => state.cumul.cumul);
  const rapports = useSelector(state => state.rapports);
  const rapport = useSelector(state => state.rapport.rapport);

  console.log('val of rapport: ', rapport)
  console.log('val of rapports: ', rapports)
  console.log('val of cumul: ', cumul)
  // On récupère tout le slice
  const {
    rapportfinancier,
    loading,
    hasErrors,
    loaded,
    donnee,
    hasMore,
    finBandeControle,
    lastEmptyMonth,
    nextMonthToLoad,
    moisStatus,
  } = useSelector(rapportfinancierSelector);

  // État local
  const [titreDonnees, setTitreDonnees] = useState([]);
  const [hasLess, sethasLess] = useState(true);
  const [pause, setPause] = useState(false);
  let timer;

  // >>> On stocke "uniqueTickets" dans un state local
  const [uniqueTicketsState, setUniqueTicketsState] = useState([]);

  // Responsiveness watchers
  const [isSmallScreen500, setIsSmallScreen500] = useState(window.innerWidth < 500);
  const [isSmallScreen600, setIsSmallScreen600] = useState(window.innerWidth < 600);
  const [isSmallScreen599, setIsSmallScreen599] = useState(window.innerWidth <= 599);
  const [isSmallScreen655, setIsSmallScreen655] = useState(window.innerWidth <= 655);
  const [isSmallScreen850, setIsSmallScreen850] = useState(window.innerWidth <= 850 && window.innerWidth >= 600);
  const [isSmallScreen1024, setIsSmallScreen1024] = useState(window.innerWidth <= 1024 && window.innerWidth > 655);
  const [isScreenUnder1024, setScreenUnder1024] = useState(window.innerWidth < 1024 && window.innerWidth > 850);

  // Au montage : vider local + fetchRapportFinancier
  useEffect(() => {
    //console.log(">>> Montage initial de RapportFinancier : on vide uniqueTickets + on fetch");
    setUniqueTicketsState([]); // vide le local
    if (rapport && service && service.length > 0 && service[0].id_service) {
      // Lance la requête => vide aussi le store via clearBandeControle()
      dispatch(fetchRapportFinancier(service[0]));
    }
  }, [dispatch, service, rapport]);

  // Watchers responsive
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen1024(window.innerWidth <= 1024 && window.innerWidth > 655);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen1024]);

  useEffect(() => {
    const handleResize = () => {
      setScreenUnder1024(window.innerWidth < 1024 && window.innerWidth > 850);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isScreenUnder1024]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen850(window.innerWidth <= 850 && window.innerWidth >= 600);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen850]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen600(window.innerWidth < 600);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen600]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen500(window.innerWidth < 500);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen500]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen655(window.innerWidth <= 655);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen655]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen599(window.innerWidth <= 599);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen599]);

  // titreDonnees si rapport_id != 5
  useEffect(() => {
    if (rapportfinancier.rapport_id !== 5) {
      if (
        rapportfinancier.sections.length > 0 &&
        rapportfinancier.sections[0].tableaux &&
        rapportfinancier.sections[0].tableaux.length > 0
      ) {
        const donnees = rapportfinancier.sections[0].tableaux[0].lignes[0].ligne_donnee;
        setTitreDonnees(donnees);
      }
    }
  }, [rapportfinancier]);

  // Menu coulissant
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getMonthAbbreviation = (dateStr) => {
    const parsedDate = parse(dateStr, 'yyyyMMdd', new Date());
    return format(parsedDate, 'LLL', { locale: fr }).toUpperCase();
  };
  
  const renderMoisProgressBar = () => {
    if (!moisStatus || moisStatus.length === 0) return null;
  
    const style = `
      /* Container de toute la barre */
      .moisRow {
        border-radius: 8px;
        padding: 8px;
        background-color: #b5b5b591;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    
      /* Chaque case mois en rectangle biseauté */
      .moisBox {
        position: relative;
        width: 50px;
        height: 30px;
        clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4px;
      }
    
      /* Le grand cercle "arc blanc" en conic-gradient, invisible par défaut */
      .moisBox::before {
        content: "";
        position: absolute;
        width: 650px;
        height: 650px;
        left: -310px;
        top: -310px;
        background-image: none;
      }
    
      /* Lorsque le mois est en chargement, on affiche l'arc blanc qui tourne */
      .moisBox.active::before {
        background-image: conic-gradient(
          transparent, transparent, transparent,
          #fff
        );
        animation: rotate 2s linear infinite;
      }
    
      @keyframes rotate {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    
      /* Overlay pour afficher le label au centre */
      .overlay {
        position: absolute;
        width: 50px;
        height: 28px;
        top: 1px;
        left: 1px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
      }
    
      /* Lorsque le statut est "no-data", on ajoute une ligne diagonale */
      .moisBox.noData::after {
        content: "";
        position: absolute;
        width: 120%;
        height: 2px;
        background-color: black;
        top: 50%;
        left: -10%;
        transform: rotate(-45deg); /* barre dans l’autre sens */
      }
    `;
    
    // Choix de la classe selon le statut
    const getStatusClass = (status) => {
      switch (status) {
        case 'loading': return 'active loading';
        case 'loaded':  return 'loaded';
        case 'no-data': return 'noData';
        default:        return 'pending';
      }
    };
    
    if(rapports.cumul){
      return (
        <div>
          <style>{style}</style>
      
          <div className="moisRow">
            {moisStatus.map((mois, index) => {
              const boxClass = `moisBox ${getStatusClass(mois.status)}`;
      
              // Définition des couleurs de fond et overlay selon le statut
              let bgColor = '#ccc';
              let overlayBg = 'transparent';
      
              if (mois.status === 'loading') {
                bgColor = '#b5b5b5';
                overlayBg = '#b5b5b5';
              } else if (mois.status === 'loaded') {
                bgColor = '#f40020';
              } else if (mois.status === 'no-data') {
                bgColor = '#b5b5b5';
              }
      
              return (
                <div
                  key={index}
                  className={boxClass}
                  style={{ backgroundColor: bgColor }}
                >
                  <span
                    className="overlay"
                    style={{ backgroundColor: overlayBg }}
                  >
                    {getMonthAbbreviation(mois.date_min)}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      );
    }else{
      return null;
    }
  };

  // Rendu du menu (inchangé)
  const renderRapportList = () => {
    if (isSmallScreen599) {
      return (
        <div className={styles.mobileWrapper}>
          <div className={`${styles.slideMenu} ${isMenuOpen ? styles.menuOpen : ''}`}>
            {rapports.rapports.map((r) => (
              <Rapport key={r.rapport_id} rapport={r} side={true} />
            ))}
            <button
              className={`${styles.menuButton} ${isMenuOpen ? styles.menuButtonOpen : ''}`}
              onClick={toggleMenu}
            >
              {isMenuOpen ? (
                <img className={`${styles.chevron}`} src={`${process.env.PUBLIC_URL}/chevron-left.svg`} alt="Fermer le menu" />
              ) : (
                <img className={`${styles.chevron}`} src={`${process.env.PUBLIC_URL}/chevron-right.svg`} alt="Ouvrir le menu" />
              )}
            </button>
            <div className={styles.menuDividerContainer}>
              <hr className={styles.menuDivider} />
            </div>
            <a href="#" onClick={handlePrint} className={styles.printLink}>
              Imprimer la page
            </a>
          </div>
        </div>
      );
    } else {
      let side = !isSmallScreen599;
      return rapports.rapports.map((r) => (
        <Rapport key={r.rapport_id} rapport={r} side={side} />
      ));
    }
  };

  const fonctionPause = () => {
    clearInterval(timer);
    sethasLess(false);
    setPause(true);
  };
  const fonctionReprise = () => {
    sethasLess(true);
    setPause(false);
  };

  // Pagination / recherche
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage, setTicketsPerPage] = useState(() => {
    const savedTicketsPerPage = Cookies.get('ticketsPerPage');
    return savedTicketsPerPage ? parseInt(savedTicketsPerPage, 10) : 10;
  });
  const [searchQuery, setSearchQuery] = useState("");

  // >>> On reconstruit un array local "uniqueTickets", puis on copie vers uniqueTicketsState
  let uniqueTickets = [];
  if (rapportfinancier.rapport_id === 5) {
    const idTable = rapportfinancier.id_table;
    //console.log('idTable: ', idTable);

    const seenKeys = new Set();
    rapportfinancier.sections.forEach((ticket) => {
      const enteteString = ticket.entete?.join("_").toLowerCase() || "";
      const uniqueKey = `${enteteString}_${ticket.id}_${idTable}`;
      if (!seenKeys.has(uniqueKey)) {
        seenKeys.add(uniqueKey);
        uniqueTickets.push(ticket);
      }
    });
    //console.log('uniqueTickets: ', uniqueTickets);
  }

  // Synchronise "uniqueTickets" -> "uniqueTicketsState"
  useEffect(() => {
    if (rapportfinancier.rapport_id === 5) {
      setUniqueTicketsState(uniqueTickets);
    }
  }, [rapportfinancier.sections]);
  // ou [rapportfinancier] si vous préférez

  // Filtrage sur uniqueTicketsState
  const filteredTickets = uniqueTicketsState.filter((ticket) => {
    if (searchQuery.length < 3) return true;
    const query = searchQuery.toLowerCase();

    const matchesEntete = ticket.entete?.some((field) => field.toLowerCase().includes(query));
    const matchesCommande = ticket.commande?.some((commande) =>
      commande.some((field) => field.toLowerCase().includes(query))
    );
    const matchesTotaux = ticket.totaux?.some((total) =>
      total.some((field) => field.toLowerCase().includes(query))
    );
    const matchesReglement = ticket.reglement?.some((reglement) =>
      reglement.some((field) => field.toLowerCase().includes(query))
    );

    return matchesEntete || matchesCommande || matchesTotaux || matchesReglement;
  });

  const totalFilteredTickets = filteredTickets.length;
  const totalPages = Math.ceil(totalFilteredTickets / ticketsPerPage);
  const currentTickets = filteredTickets.slice(
    (currentPage - 1) * ticketsPerPage,
    currentPage * ticketsPerPage
  );

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [currentPage, totalPages]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleTicketsPerPageChange = (event) => {
    const newTicketsPerPage = parseInt(event.target.value, 10);
    setTicketsPerPage(newTicketsPerPage);
    Cookies.set('ticketsPerPage', newTicketsPerPage, { expires: 30 });
    setCurrentPage(1);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  // Header pour les Totaux
  const renderHeaderTotaux = (ligne, isSmallScreen655, tableau_id) => {
    if (ligne.ligne_type === 'titre') {
      if (rapport.rapport_id == 1) {
        // Modification spécifique pour rapport_id 1
        if (isSmallScreen655) {
          if (tableau_id == 1) {
            return (
              <tr className={styles.rapport4_table}>
                {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                  if (index % 2 === 0) {
                    acc.push(
                      <th className={styles.rapport4_table} key={index} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                        {donnee}
                      </th>
                    );
                    if (ligne.ligne_donnee[index + 1]) {
                      acc.push(
                        <th className={styles.rapport4_table} key={index + 1} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                          {ligne.ligne_donnee[index + 1]}
                        </th>
                      );
                    }
                  }
                  return acc;
                }, [])}
              </tr>
            );
          }
          if (tableau_id == 2) {
            return (
              <>
                <tr>
                  <th colSpan={ligne.ligne_donnee.length}>{ligne.ligne_donnee[0]}</th>
                </tr>
                <tr>
                  {ligne.ligne_donnee.slice(1).map((donnee, index) => (
                    <th key={index}>{donnee}</th>
                  ))}
                </tr>
              </>
            );
          }
        } else {
          // Modification pour aligner les en-têtes avec les deux colonnes côte à côte
          return (
            <tr className={styles.rapport4_table}>
              {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                if (index % 2 === 0) {
                  acc.push(
                    <th className={styles.rapport4_table} key={index} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                      {donnee}
                    </th>
                  );
                  if (ligne.ligne_donnee[index + 1]) {
                    acc.push(
                      <th className={styles.rapport4_table} key={index + 1} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                        {ligne.ligne_donnee[index + 1]}
                      </th>
                    );
                  }
                }
                return acc;
              }, [])}
            </tr>
          );
        }
      } else if (rapport.rapport_id == 4) {
        if (isSmallScreen655) {
          return (
            <>
              <tr className={styles.rapport4_table}>
                <th className={styles.rapport4_table} colSpan={5}>{ligne.ligne_donnee[0]}</th> {/* Groupe */}
              </tr>
              <tr className={styles.rapport4_table}>
                <th className={styles.rapport4_table}>
                  <div>{ligne.ligne_donnee[1]}</div> {/* T.T.C. */}
                  <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[2]}</div> {/* H.T./H.S. */}
                </th>
                <th className={styles.rapport4_table}>
                  <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                  <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* Service */}
                </th>
              </tr>
            </>
          );
        } else {
          return (
            <tr className={styles.rapport4_table}>
              {ligne.ligne_donnee.map((donnee, index) => (
                <th className={styles.rapport4_table} key={index}>
                  {donnee}
                </th>
              ))}
            </tr>
          );
        }
      } else {
        if (ligne.ligne_type === 'titre') {
          return (ligne.ligne_donnee.map((donnee) => <th>{donnee}</th>));
        }
      }
    }
  };

  // Body pour les Totaux
  const renderBodyTotaux = (ligne, isSmallScreen655, tableau_id) => {
    if (ligne.ligne_type === 'valeur') {
      if (rapport.rapport_id == 1) {
        // Modification spécifique pour rapport_id 1
        if (isSmallScreen655) {
          if (tableau_id == 1) {
            return (
              <>
                {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                  // On s'assure que toutes les lignes soient bien prises en compte
                  if (index % 2 === 0) {
                    acc.push(
                      <tr key={index} style={{ borderBottom: '1px solid #e7e7e7' }}>
                        <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                          {donnee || '0'}
                        </td>
                        {ligne.ligne_donnee[index + 1] !== undefined && (
                          <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                            {ligne.ligne_donnee[index + 1] || '0'}
                          </td>
                        )}
                      </tr>
                    );
                  }
                  return acc;
                }, [])}
              </>
            );
          }
          if (tableau_id == 2) {
            return (
              <>
                {/* Chaque ligne occupe une seule colonne en petit écran */}
                <tr style={{ borderBottom: '1px solid #e7e7e7' }}>
                  <th colSpan={ligne.ligne_donnee.length} style={{ fontWeight: 'normal', textAlign: 'center', backgroundColor: '#e7e7e7', padding: '8px 0px' }}>{ligne.ligne_donnee[0]}</th>
                </tr>
                <tr style={{ borderBottom: '1px solid #e7e7e7' }}>
                  {ligne.ligne_donnee.slice(1).map((donnee, index) => (
                    <th key={index} style={{ fontWeight: 'normal', textAlign: 'center', border: '1px solid #DDDDDD', padding: '8px 0px' }}>{donnee}</th>
                  ))}
                </tr>
              </>
            );
          }
        } else {
          // Modification pour afficher deux colonnes par ligne pour les grands écrans
          if (tableau_id == 1) {
            return (
              <>
                {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                  // On s'assure que toutes les lignes soient bien prises en compte
                  if (index % 2 === 0) {
                    acc.push(
                      <tr key={index} style={{ borderBottom: '1px solid #e7e7e7' }}>
                        <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                          {donnee || '0'}
                        </td>
                        {ligne.ligne_donnee[index + 1] !== undefined && (
                          <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                            {ligne.ligne_donnee[index + 1] || '0'}
                          </td>
                        )}
                      </tr>
                    );
                  }
                  return acc;
                }, [])}
              </>
            );
          }
          if (tableau_id == 2) {
            return (
              <>
                {/* Toutes les valeurs sont affichées sur une seule ligne */}
                <tr style={{ border: '1px solid #e7e7e7' }}>
                  {ligne.ligne_donnee.map((donnee, index) => (
                    <th key={index} style={{ fontWeight: 'normal', textAlign: 'center', border: '1px solid #DDD', padding: '8px 0px' }}>
                      {donnee}
                    </th>
                  ))}
                </tr>
              </>
            );
          }
        }
      } else if (rapport.rapport_id == 4) {
        const firstCellClass = `${styles.rapport4_table} ${styles.first_cell}`; // Classe spéciale pour la première cellule
        if (isSmallScreen655) {
          return (
            <>
              <tr className={styles.rapport4_table}>
                <td className={firstCellClass} colSpan={5}>{ligne.ligne_donnee[0]}</td> {/* Groupe */}
              </tr>
              <tr className={styles.rapport4_table}>
                <td className={styles.rapport4_table}>
                  <div>{ligne.ligne_donnee[1]}</div> {/* T.T.C. */}
                  <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[2]}</div> {/* H.T./H.S. */}
                </td>
                <td className={styles.rapport4_table}>
                  <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                  <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* Service */}
                </td>
              </tr>
            </>
          );
        } else {
          return (
            <tr className={styles.rapport4_table}>
              <td className={firstCellClass}>{ligne.ligne_donnee[0]}</td> {/* Groupe */}
              {ligne.ligne_donnee.slice(1).map((donnee, index) => (
                <td className={styles.rapport4_table} key={index}>
                  {donnee} {/* T.T.C., H.T./H.S., T.V.A., Service */}
                </td>
              ))}
            </tr>
          );
        }
      } else {
        if (ligne.ligne_type === 'valeur') {
          return (ligne.ligne_donnee.map((donnee, index) => <td id={index}>{donnee}</td>));
        }
      }
    }
  };

  // Header pour Famille
  const renderHeaderFamille = (ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850, index) => {
    const screenSize = isSmallScreen655
      ? "isSmallScreen655"
      : isSmallScreen1024
        ? "isSmallScreen1024"
        : null;

    if (ligne.ligne_type === 'titre') {
      if (rapport.rapport_id == 1) {
        if (index % 2 === 0) {
          // Tableau pair avec 3 colonnes
          if (isSmallScreen655) {
            return (
              <tr className={styles.rapport4_table}>
                {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                  if (index % 2 === 0) {
                    acc.push(
                      <th className={styles.rapport4_table} key={index} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                        {donnee}
                      </th>
                    );
                    if (ligne.ligne_donnee[index + 1]) {
                      acc.push(
                        <th className={styles.rapport4_table} key={index + 1} style={{ fontWeight: 'bold', textAlign: 'center', padding: '8px' }}>
                          {ligne.ligne_donnee[index + 1]}
                        </th>
                      );
                    }
                  }
                  return acc;
                }, [])}
              </tr>
            );
          } else {
            return (
              <tr>
                {ligne.ligne_donnee.slice(0, 3).map((donnee, index) => (
                  <td key={index} style={{ textAlign: 'center', borderBottom: '1px solid #ddd', backgroundColor: '#262626', color: 'white' }}>{donnee}</td>
                ))}
              </tr>
            );
          }
        } else {
          // Tableau impair avec 5 colonnes
          if (isSmallScreen655) {
            return (
              <>
                <tr>
                  <td colSpan={5} style={{ textAlign: 'center', backgroundColor: '#262626', color: 'white' }}>
                    {ligne.ligne_donnee[0]}
                  </td>
                </tr>
                <tr>
                  {ligne.ligne_donnee.slice(1, 6).map((donnee, index) => (
                    <td key={index} style={{ textAlign: 'center', borderBottom: '1px solid #ddd', backgroundColor: '#262626', color: 'white' }}>{donnee}</td>
                  ))}
                </tr>
              </>
            );
          } else {
            return (
              <tr>
                {ligne.ligne_donnee.slice(0, 5).map((donnee, index) => (
                  <td key={index} style={{ textAlign: 'center', borderBottom: '1px solid #ddd', backgroundColor: '#262626', color: 'white' }}>{donnee}</td>
                ))}
              </tr>
            );
          }
        }
      } else if (rapportfinancier.rapport_id == 2) {
        if (ligne.ligne_type == 'titre') {
          if (isSmallScreen600) {
            // Logique pour les écrans < 600px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCelltitre} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if (index === 1) {
                    return null; // Masquer "Heure" sous 600px
                  }
                  if (index === 2) {
                    return (
                      <th key={index} className={`${styles.rapport4_table} ${styles.mobileHide}`} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        {donnee}
                      </th>
                    );
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else if (isSmallScreen850) {
            // Logique pour les écrans entre 600px et 850px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCelltitre} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if (index === 1) {
                    return null; // Masquer "Heure" sous 600px
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else {
            // Logique pour les écrans au-delà de 850px
            // Header tableau pour les rapports d'annulation et d'offert
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}> {/* Classe spécifique pour l'en-tête */}
                {ligne.ligne_donnee.map(function (donnee, index) {
                  if (index === 6) { // Ne pas afficher la colonne 'Type' dans l'en-tête
                    return null;
                  }
                  return <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>{donnee}</th>; {/* Classe spécifique pour chaque cellule */ }
                })}
              </tr>
            );
          }
        }
      } else if (rapportfinancier.rapport_id == 3) {
        if (ligne.ligne_type == 'titre') {
          if (isSmallScreen600) {
            // Logique pour les écrans < 600px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCelltitre} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if (index === 1) {
                    return null; // Masquer "Heure" sous 600px
                  }
                  if (index === 2) {
                    return (
                      <th key={index} className={`${styles.rapport4_table} ${styles.mobileHide}`} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        {donnee}
                      </th>
                    );
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else if (isSmallScreen850) {
            // Logique pour les écrans entre 600px et 850px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCelltitre} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if (index === 1) {
                    return null; // Masquer "Heure" sous 600px
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else {
            // Logique pour les écrans au-delà de 850px
            // Header tableau pour les rapports d'annulation et d'offert
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}> {/* Classe spécifique pour l'en-tête */}
                {ligne.ligne_donnee.map(function (donnee, index) {
                  if (index === 6) { // Ne pas afficher la colonne 'Type' dans l'en-tête
                    return null;
                  }
                  return <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>{donnee}</th>; {/* Classe spécifique pour chaque cellule */ }
                })}
              </tr>
            );
          }
        }
      } else if (rapportfinancier.rapport_id == 7) {
        // Header tableau pour le rapport offert perso
        if (ligne.ligne_type == 'titre') {
          if (isSmallScreen600) {
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCell} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if (index === 1) {
                    return null
                  }
                  if (index === 2) {
                    return (
                      <th key={index} className={`${styles.rapport4_table} ${styles.mobileHide}`} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        {donnee}
                      </th>
                    );
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else if (isSmallScreen850) {
            // Logique pour les écrans entre 600px et 850px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                {ligne.ligne_donnee.map((donnee, index) => {
                  if (index === 0) {
                    return (
                      <th key={index} className={styles.tableHeureCell} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                        <div>Table</div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '4px', paddingTop: '4px' }}>Heure</div>
                      </th>
                    );
                  }
                  if (index === 1) {
                    return null
                  }
                  if (index === 6) {
                    return null;
                  }
                  return (
                    <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                      {donnee}
                    </th>
                  );
                })}
              </tr>
            );
          } else {
            // Logique pour les écrans au-delà de 850px
            return (
              <tr className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}> {/* Classe spécifique pour l'en-tête */}
                {ligne.ligne_donnee.map(function (donnee, index) {
                  if (index === 6) { // Ne pas afficher la colonne 'Type' dans l'en-tête
                    return null;
                  }
                  return <th key={index} className={styles.rapport4_table} style={{ backgroundColor: '#262626', textAlign: 'center' }}>{donnee}</th>; {/* Classe spécifique pour chaque cellule */ }
                })}
              </tr>
            );
          }
        }
      } else if (rapport.rapport_id == 4) {
        const columnCount = ligne.ligne_donnee.length - 1; // Compte les colonnes à afficher (sans le Code)
        if (rapports.cumul) {
          // Switch case pour les différentes tailles d'écran
          switch (screenSize) {
            case "isSmallScreen655":
              // Cas où la fenêtre est <= 655px
              const filteredDonnees655 = ligne.ligne_donnee.filter((donnee) => donnee.toLowerCase() !== 'ratios'); // Enlever "Ratios"
              return (
                <>
                  <tr className={styles.rapport4_table}>
                    <th className={styles.rapport4_table}>{filteredDonnees655[0]}</th> {/* Code */}
                    <th className={styles.rapport4_table} colSpan={2}>{filteredDonnees655[1]}</th> {/* Désignation avec colSpan sous 655px */}
                  </tr>
                  <tr className={styles.rapport4_table}>
                    <th className={styles.rapport4_table}>
                      <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                      <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                    </th>
                    <th className={styles.rapport4_table}>
                      <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                      <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                    </th>
                    <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                  </tr>
                </>
              );
            case "isSmallScreen1024":
              // Cas où la fenêtre est entre 655px et 1024px
              const filteredDonnees1024 = ligne.ligne_donnee.filter((donnee) => donnee.toLowerCase() !== 'ratios'); // Enlever "Ratios"
              return (
                <>
                  <tr className={styles.rapport4_table}>
                    <th className={styles.rapport4_table}>{filteredDonnees1024[0]}</th> {/* Code */}
                    <th className={styles.rapport4_table}>{filteredDonnees1024[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
                    {filteredDonnees1024.slice(2).map((donnee, index) => (
                      <th className={styles.rapport4_table} key={index}>
                        {donnee} {/* QTE Vendus, T.T.C., etc. */}
                      </th>
                    ))}
                  </tr>
                </>
              );

            default:
              // Cas par défaut (logique actuelle de l'else)
              return (
                <tr className={styles.rapport4_table}>
                  <th className={styles.rapport4_table}>{ligne.ligne_donnee[0]}</th> {/* Code */}
                  <th className={styles.rapport4_table}>{ligne.ligne_donnee[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
                  {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                    <th className={styles.rapport4_table} key={index}>
                      {donnee} {/* QTE Vendus, T.T.C., etc. */}
                    </th>
                  ))}
                </tr>
              );
          }
        } else {
          const filteredDonnees = ligne.ligne_donnee.filter(donnee => {
            if (typeof donnee === 'string') {
              return donnee.trim().toLowerCase() !== 'ratios';
            }
            return true;
          });

          if (isSmallScreen655) {
            return (
              <>
                <tr className={styles.rapport4_table}>
                  <th className={styles.rapport4_table}>{filteredDonnees[0]}</th> {/* Code */}
                  <th className={styles.rapport4_table} colSpan={2}>{filteredDonnees[1]}</th> {/* Désignation avec colSpan sous 655px */}
                </tr>
                <tr className={styles.rapport4_table}>
                  <th className={styles.rapport4_table}>
                    <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                    <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                  </th>
                  <th className={styles.rapport4_table}>
                    <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                    <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                  </th>
                  <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                </tr>
              </>
            );
          } else {
            return (
              <tr className={styles.rapport4_table}>
                <th className={styles.rapport4_table}>{filteredDonnees[0]}</th> {/* Code */}
                <th className={styles.rapport4_table}>{filteredDonnees[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
                {filteredDonnees.slice(2).map((donnee, index) => (
                  <th className={styles.rapport4_table} key={index}>
                    {donnee} {/* QTE Vendus, T.T.C., etc. */}
                  </th>
                ))}
              </tr>
            );
          }
        }
      } else if (rapport.rapport_id == 6) {
        const columnCount = ligne.ligne_donnee.length - 1; // Compte les colonnes à afficher (sans le Code)
        if (rapports.cumul) {
          // Filtrez les données pour enlever "ratios" si nécessaire et ajustez la visualisation pour les écrans > 655px
          const filteredDonnees1024 = ligne.ligne_donnee.filter((donnee) => donnee.toLowerCase() !== 'ratios');

          return (
            <tr className={styles.rapport6_table}> {/* Utilisez la nouvelle classe pour centrer les éléments */}
              {filteredDonnees1024.map((donnee, index) => (
                <th className={styles.rapport6_table} key={index}>
                  {donnee} {/* Affichez le code, la désignation et les autres données comme QTE Vendus, T.T.C., etc. */}
                </th>
              ))}
            </tr>
          );
        } else {
          const filteredDonnees = ligne.ligne_donnee.filter(donnee => {
            if (typeof donnee === 'string') {
              return donnee.trim().toLowerCase() !== 'ratios';
            }
            return true;
          });
          return (
            <tr className={styles.rapport4_table}>
              <th className={styles.rapport4_table}>{filteredDonnees[0]}</th> {/* Code */}
              <th className={styles.rapport4_table}>{filteredDonnees[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
              {filteredDonnees.slice(2).map((donnee, index) => (
                <th className={styles.rapport4_table} key={index}>
                  {donnee} {/* QTE Vendus, T.T.C., etc. */}
                </th>
              ))}
            </tr>
          );
        }
      } else {
        if (ligne.ligne_type === 'valeur') {
          return (ligne.ligne_donnee.map((donnee, index) => <td id={index}>{donnee}</td>));
        }
      }
    }
  };

  //console.log("value de rapport id", rapportfinancier.rapport_id)
  // Body pour Famille
  const renderBodyFamille = (ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850, index, tableau_id) => {
    if (rapport.rapport_id == 1) {
      if (ligne.ligne_type == 'valeur') {
        if (index % 2 === 0) {
          // Tableau pair avec 3 colonnes
          if (isSmallScreen655) {
            return (
              <>
                {ligne.ligne_donnee.reduce((acc, donnee, index) => {
                  // On s'assure que toutes les lignes soient bien prises en compte
                  if (index % 2 === 0) {
                    acc.push(
                      <tr key={index} style={{ borderBottom: '1px solid #e7e7e7' }}>
                        <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                          {donnee || '0'}
                        </td>
                        {ligne.ligne_donnee[index + 1] !== undefined && (
                          <td className={styles.rapport4_table} style={{ textAlign: 'center', padding: '8px' }}>
                            {ligne.ligne_donnee[index + 1] || '0'}
                          </td>
                        )}
                      </tr>
                    );
                  }
                  return acc;
                }, [])}
              </>
            );
          } else {
            return (
              <tr>
                {ligne.ligne_donnee.slice(0, 3).map((donnee, index) => (
                  <td key={index} style={{ textAlign: 'center', border: '1px solid #ddd' }}>{donnee}</td>
                ))}
              </tr>
            );
          }
        } else {
          // Tableau impair avec 5 colonnes
          if (isSmallScreen655) {
            return (
              <>
                <tr>
                  <td colSpan={5}>{ligne.ligne_donnee[0]}</td>
                </tr>
                <tr>
                  {ligne.ligne_donnee.slice(1, 6).map((donnee, index) => (
                    <td key={index} style={{ textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#e7e7e7' }}>{donnee}</td>
                  ))}
                </tr>
              </>
            );
          } else {
            return (
              <tr>
                {ligne.ligne_donnee.slice(0, 5).map((donnee, index) => (
                  <td key={index} style={{ textAlign: 'center', border: '1px solid #ddd' }}>{donnee}</td>
                ))}
              </tr>
            );
          }
        }
      }
    } else if (rapportfinancier.rapport_id == 2) {
      if (ligne.ligne_type == 'valeur') {
        if (isSmallScreen600) {
          // Gérer les lignes de totaux spécifiquement
          if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
            return (
              <>
                <td key="total-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                <td></td>
              </>
            );
          }
          if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
            return (
              <>
                <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                <td></td>
                <td></td>
              </>
            );
          }

          // Affichage normal pour les autres lignes de données
          return ligne.ligne_donnee.map((donnee, index) => {
            // Fusionner Table et Heure sous 600px
            if (index === 0) {
              return (
                <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                  <div>{ligne.ligne_donnee[0]}</div>
                  <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                    {ligne.ligne_donnee[1].substring(0, 5)}
                  </div>
                </td>
              );
            }

            // Ne pas afficher la colonne Heure après fusion avec Table
            if (index === 1) {
              return null;
            }

            // Masquer la colonne Code sous 600px
            if (index === 2) {
              return (
                <td key={index} className={styles.mobileHide} style={{ textAlign: 'center' }}>
                  {donnee}
                </td>
              );
            }

            // Fusionner Prix et Type
            if (index === 5) {
              const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
              return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
            }

            // Ne pas afficher la colonne Type après fusion avec Prix
            if (index === 6) {
              return null;
            }

            // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
            if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
              return null;
            } else {
              return <td key={index} style={{ textAlign: 'center', borderTop: "1px solid #e0e0e0" }}>{donnee}</td>;
            }
          }
          );
        } else if (isSmallScreen850) {
          // Logique pour les écrans entre 600px et 850px
          if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
            return (
              <>
                <td key="total-label" colSpan="3" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                <td></td>
              </>
            );
          }

          if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
            return (
              <>
                <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                <td></td>
              </>
            );
          }

          // Affichage normal pour les autres lignes de données
          return ligne.ligne_donnee.map((donnee, index) => {
            // Fusionner Table et Heure sous 600px
            if (index === 0) {
              return (
                <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                  <div>{ligne.ligne_donnee[0]}</div>
                  <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                    {ligne.ligne_donnee[1].substring(0, 5)}
                  </div>
                </td>
              );
            }

            // Ne pas afficher la colonne Heure après fusion avec Table
            if (index === 1) {
              return null;
            }

            // Fusionner Prix et Type
            if (index === 5) {
              const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
              return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
            }

            // Ne pas afficher la colonne Type après fusion avec Prix
            if (index === 6) {
              return null;
            }

            // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
            if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
              // Ne pas afficher la colonne raison pour les autres types de rapport (offert et offert perso)
              return null;
            } else {
              return <td key={index} style={{ textAlign: 'center', borderTop: "1px solid #e0e0e0" }}>{donnee}</td>;
            }
          });
        } else {
          // Logique pour les écrans au-delà de 850px      
          // Gérer les lignes de totaux spécifiquement
          if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
            return (
              <>
                <td key="total-label" colSpan="4" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                <td style={{ borderTop: "1px solid #e0e0e0" }}></td>
              </>
            );
          }

          if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
            return (
              <>
                <td key="total-servers-label" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }

          // Affichage normal pour les autres lignes de données
          return ligne.ligne_donnee.map((donnee, index) => {
            // Formatage de l'heure au format hh:mm
            if (index === 1 && typeof donnee === 'string') {
              donnee = donnee.substring(0, 5);
            }

            // Fusionner Prix et Type
            if (index === 5) {
              const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
              return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
            }

            // Ne pas afficher la colonne Type après fusion avec Prix
            if (index === 6) {
              return null;
            }

            // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
            if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
              // Ne pas afficher la colonne raison pour les autres types de rapport (offert et offert perso)
              return null;
            } else {
              return <td key={index} style={{ textAlign: 'center', borderTop: "1px solid #e0e0e0" }}>{donnee}</td>;
            }
          });
        }
      }
    } else if (rapportfinancier.rapport_id == 3) {
      if (ligne.ligne_type == 'valeur') {
        if (isSmallScreen600) {
          // Gérer les lignes de totaux spécifiquement
          if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
            return (
              <>
                <td key="total-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }

          if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
            return (
              <>
                <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }

          // Affichage normal pour les autres lignes de données
          return ligne.ligne_donnee.map((donnee, index) => {
            // Fusionner Table et Heure sous 600px
            if (index === 0) {
              return (
                <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                  <div>{ligne.ligne_donnee[0]}</div>
                  <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                    {ligne.ligne_donnee[1].substring(0, 5)}
                  </div>
                </td>
              );
            }

            // Ne pas afficher la colonne Heure après fusion avec Table
            if (index === 1) {
              return null;
            }

            // Masquer la colonne Code sous 600px
            if (index === 2) {
              return (
                <td key={index} className={styles.mobileHide} style={{ textAlign: 'center' }}>
                  {donnee}
                </td>
              );
            }

            // Fusionner Prix et Type
            if (index === 5) {
              const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
              return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
            }

            // Ne pas afficher la colonne Type après fusion avec Prix
            if (index === 6) {
              return null;
            }

            // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
            if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
              return null;
            } else {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            }
          });
        } else if (isSmallScreen850) {
          // Logique pour les écrans entre 600px et 850px
          if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
            return (
              <>
                <td key="total-label" colSpan="3" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }

          if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
            return (
              <>
                <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }

          // Affichage normal pour les autres lignes de données
          return ligne.ligne_donnee.map((donnee, index) => {
            // Fusionner Table et Heure sous 600px
            if (index === 0) {
              return (
                <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                  <div>{ligne.ligne_donnee[0]}</div>
                  <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                    {ligne.ligne_donnee[1].substring(0, 5)}
                  </div>
                </td>
              );
            }

            // Ne pas afficher la colonne Heure après fusion avec Table
            if (index === 1) {
              return null;
            }

            // Fusionner Prix et Type
            if (index === 5) {
              const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
              return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
            }

            // Ne pas afficher la colonne Type après fusion avec Prix
            if (index === 6) {
              return null;
            }

            // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
            if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
              // Ne pas afficher la colonne raison pour les autres types de rapport (offert et offert perso)
              return null;
            } else {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            }
          });
        } else {
          // Logique pour les écrans au-delà de 850px      
          // Gérer les lignes de totaux spécifiquement
          if (ligne.ligne_donnee[0] === 'Total annulations' || ligne.ligne_donnee[0] === 'Total offerts') {
            return (
              <>
                <td key="total-label" colSpan="4" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }

          if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
            return (
              <>
                <td key="total-servers-label" colSpan="1" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }

          // Affichage normal pour les autres lignes de données
          return ligne.ligne_donnee.map((donnee, index) => {
            // Formatage de l'heure au format hh:mm
            if (index === 1 && typeof donnee === 'string') {
              donnee = donnee.substring(0, 5);
            }

            // Fusionner Prix et Type
            if (index === 5) {
              const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
              return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
            }

            // Ne pas afficher la colonne Type après fusion avec Prix
            if (index === 6) {
              return null;
            }

            // Affichage conditionnel de la colonne raison pour le type annulation (rapport_id = 2)
            if (rapportfinancier.rapport_id === 2 && index === ligne.ligne_donnee.length - 1) {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            } else if (rapportfinancier.rapport_id !== 2 && index === ligne.ligne_donnee.length - 1) {
              // Ne pas afficher la colonne raison pour les autres types de rapport (offert et offert perso)
              return null;
            } else {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            }
          });
        }
      }
    } else if (rapport.rapport_id == 4) {
      const screenSize = isSmallScreen655
        ? "isSmallScreen655"
        : isSmallScreen1024
          ? "isSmallScreen1024"
          : null;

      // Vérifier que ligne et ligne.ligne_donnee existent et sont valides
      if (!ligne || !ligne.ligne_donnee || ligne.ligne_donnee.length === 0) {
        return null;  // Ne rien rendre si les données sont manquantes ou invalides
      }

      const firstCellClass = `${styles.rapport4_table} ${styles.first_cell}`;

      // Filtrer les données en supprimant la dernière colonne uniquement pour les tailles d'écran spécifiques
      let filteredDonnees = ligne.ligne_donnee;

      if (screenSize === "isSmallScreen655" || screenSize === "isSmallScreen1024") {
        filteredDonnees = ligne.ligne_donnee.slice(0, -1);  // Supprimer la dernière colonne (Ratios)
      }

      if (ligne.ligne_type === 'valeur') {
        // Vérification spécifique pour la ligne "Total famille"
        if (ligne.ligne_donnee[0] === 'Total famille') {
          if (screenSize == "isSmallScreen655") {
            return (
              <>
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass} colSpan={isSmallScreen655 ? 3 : ligne.ligne_donnee.length + 1}>
                    Total famille
                  </td>
                </tr>
                <tr className={styles.rapport4_table}>
                  {/* Protéger le rendu avec un fallback pour les valeurs manquantes */}
                  <th className={styles.rapport4_table}>
                    <div>{ligne.ligne_donnee[1]}</div> {/* T.T.C. */}
                    <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[3]}</div> {/* H.T./H.S. */}
                  </th>
                  <th className={styles.rapport4_table}>
                    <div>{ligne.ligne_donnee[2]}</div> {/* T.V.A. */}
                    <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* Service */}
                  </th>
                  <th className={styles.rapport4_table}>{ligne.ligne_donnee[5]}</th>
                </tr>
              </>
            );
          } else {
            return (
              <>
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass} colSpan={isSmallScreen655 ? 3 : ligne.ligne_donnee.length + 1}>
                    Total famille
                  </td>
                </tr>

                <tr className={styles.rapport4_table}>
                  {!isSmallScreen655 && (
                    <td className={styles.rapport4_table} colSpan={2}>-</td>
                  )}
                  {/* Protéger le rendu avec un fallback pour les valeurs manquantes */}
                  {ligne.ligne_donnee.slice(1).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'} {/* Si donnee est null ou undefined */}
                    </td>
                  ))}
                </tr>
              </>
            );
          }
        }

        // Pour toutes les autres lignes, vérifier le mode responsive et rendre les données
        if (rapports.cumul) {
          // Si rapports.cumul est vrai
          switch (screenSize) {
            case "isSmallScreen655":
              return (
                <>
                  <tr className={styles.rapport4_table}>
                    <td className={firstCellClass}>{filteredDonnees[0] || '0'}</td>  {/* Code */}
                    <td className={firstCellClass} colSpan={2}>{filteredDonnees[1] || '0'}</td>  {/* Désignation */}
                  </tr>
                  <tr className={styles.rapport4_table}>
                    <th className={styles.rapport4_table}>
                      <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                      <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                    </th>
                    <th className={styles.rapport4_table}>
                      <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                      <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                    </th>
                    <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                  </tr>
                </>
              );
            case "isSmallScreen1024":
              return (
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass}>{filteredDonnees[0] || '0'}</td>  {/* Code */}
                  <td className={styles.rapport4_table}>{filteredDonnees[1] || '0'}</td>  {/* Désignation */}
                  {filteredDonnees.slice(2).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'}  {/* Protéger contre les données manquantes */}
                    </td>
                  ))}
                </tr>
              );

            default:
              return (
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>  {/* Code */}
                  <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>  {/* Désignation */}
                  {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'}  {/* Protéger contre les données manquantes */}
                    </td>
                  ))}
                </tr>
              );
          }
        } else {
          // Si rapports.cumul est faux (ancien switch sans suppression de "Ratios")
          switch (screenSize) {
            case "isSmallScreen655":
              return (
                <>
                  <tr className={styles.rapport4_table}>
                    <td className={firstCellClass}>{filteredDonnees[0] || '0'}</td>  {/* Code */}
                    <td className={firstCellClass} colSpan={2}>{filteredDonnees[1] || '0'}</td>  {/* Désignation */}
                  </tr>
                  <tr className={styles.rapport4_table}>
                    <th className={styles.rapport4_table}>
                      <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                      <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                    </th>
                    <th className={styles.rapport4_table}>
                      <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                      <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                    </th>
                    <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                  </tr>
                </>
              );


            case "isSmallScreen1024":
              return (
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>  {/* Code */}
                  <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>  {/* Désignation */}
                  {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'}  {/* Protéger contre les données manquantes */}
                    </td>
                  ))}
                </tr>
              );

            default:
              return (
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>  {/* Code */}
                  <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>  {/* Désignation */}
                  {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'}  {/* Protéger contre les données manquantes */}
                    </td>
                  ))}
                </tr>
              );
          }
        }
      }
    } else if (rapport.rapport_id == 6) {
      const firstCellClass = `${styles.rapport4_table} ${styles.first_cell}`;

      if (ligne.ligne_type === 'valeur') {
        // Pour toutes les autres lignes, vérifier le mode responsive et rendre les données
        if (rapports.cumul) {
          if (ligne.ligne_donnee[0] !== 'Total') {
            const combinedValues = [];
            combinedValues.push(ligne.ligne_donnee[0]); // Première valeur, le numéro et le serveur
            combinedValues.push(ligne.ligne_donnee[1]); // Deuxième valeur, le total C.A.

            // Combinez chaque donnée numérique avec son pourcentage suivant
            for (let i = 2; i < ligne.ligne_donnee.length; i += 2) {
              if (i + 1 < ligne.ligne_donnee.length) {
                combinedValues.push(`${ligne.ligne_donnee[i]} - ${ligne.ligne_donnee[i + 1]}`);
              } else {
                combinedValues.push(ligne.ligne_donnee[i]); // Ajouter la dernière valeur si pas de pourcentage suivant
              }
            }

            return (
              <tr className={styles.rapport6_table}>
                {combinedValues.map((value, index) => (
                  <td className={styles.rapport6_table} key={index}>
                    {value || '0'}
                  </td>
                ))}
              </tr>
            );
          }

        } else {
          if (ligne.ligne_donnee[0] !== 'Total') {
            // Si rapports.cumul est faux (ancien switch sans suppression de "Ratios")
            return (
              <tr className={styles.rapport4_table}>
                <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>
                <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>
                {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                  <td className={styles.rapport4_table} key={index}>
                    {donnee || '0'}
                  </td>
                ))}
              </tr>
            );
          }
        }
      }
    } else if (rapportfinancier.rapport_id == 7) {
      // Body tableau pour le rapport offert perso
      if (ligne.ligne_type == 'valeur') {
        if (isSmallScreen600) {
          // Gérer les lignes de totaux spécifiquement
          if (ligne.ligne_donnee[0] === 'Total offerts') {
            return (
              <>
                <td key="total-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }

          if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
            return (
              <>
                <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                  {ligne.ligne_donnee[0]}
                </td>
                <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
              </>
            );
          }

          // Affichage normal pour les autres lignes de données
          return ligne.ligne_donnee.map((donnee, index) => {
            // Fusionner Table et Heure sous 600px
            if (index === 0) {
              return (
                <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                  <div>{ligne.ligne_donnee[0]}</div>
                  <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                    {ligne.ligne_donnee[1].substring(0, 5)}
                  </div>
                </td>
              );
            }

            // Ne pas afficher la colonne Heure après fusion avec Table
            if (index === 1) {
              return null;
            }

            // Masquer la colonne Code sous 600px
            if (index === 2) {
              return (
                <td key={index} className={styles.mobileHide} style={{ textAlign: 'center' }}>
                  {donnee}
                </td>
              );
            }

            // Fusionner Prix et Type
            if (index === 5) {
              const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
              return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
            }

            // Ne pas afficher la colonne Type après fusion avec Prix
            if (index === 6) {
              return null;
            }

            // Ignorer la colonne raison pour le type offert perso
            if (index === ligne.ligne_donnee.length - 1) {
              return null;
            } else {
              return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
            }
          });
        } else if (isSmallScreen850) {
          // Logique pour les écrans entre 600px et 850px
          // Body tableau pour le rapport offert perso
          if (ligne.ligne_type == 'valeur') {
            // Gérer les lignes de totaux spécifiquement
            if (ligne.ligne_donnee[0] === 'Total offerts') {
              return (
                <>
                  <td key="total-label" colSpan="3" className={styles.total} style={{ textAlign: 'center' }}>
                    {ligne.ligne_donnee[0]}
                  </td>
                  <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                  <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                </>
              );
            }

            if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
              return (
                <>
                  <td key="total-servers-label" colSpan="2" className={styles.total} style={{ textAlign: 'center' }}>
                    {ligne.ligne_donnee[0]}
                  </td>
                  <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                  <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                </>
              );
            }

            // Affichage normal pour les autres lignes de données
            return ligne.ligne_donnee.map((donnee, index) => {
              // Fusionner Table et Heure sous 600px
              if (index === 0) {
                return (
                  <td key={index} className={`${styles.tableHeureCell}`} style={{ textAlign: 'center' }}>
                    <div>{ligne.ligne_donnee[0]}</div>
                    <div style={{ borderTop: '1px solid #ccc', paddingTop: '4px' }}>
                      {ligne.ligne_donnee[1].substring(0, 5)}
                    </div>
                  </td>
                );
              }

              // Ne pas afficher la colonne Heure après fusion avec Table
              if (index === 1) {
                return null;
              }

              // Fusionner Prix et Type
              if (index === 5) {
                const prixTypeValue = `${ligne.ligne_donnee[5]} ${ligne.ligne_donnee[6]}`;
                return <td key={index} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
              }

              // Ne pas afficher la colonne Type après fusion avec Prix
              if (index === 6) {
                return null;
              }


              // Ignorer la colonne raison pour le type offert perso
              if (index === ligne.ligne_donnee.length - 1) {
                return null;
              } else {
                return <td key={index} style={{ textAlign: 'center' }}>{donnee}</td>;
              }
            });
          }
        } else {
          // Logique pour les écrans au-delà de 850px
          // Body tableau pour le rapport offert perso
          if (ligne.ligne_type == 'valeur') {

            if (ligne.ligne_donnee[0] === 'Total offerts') {
              return (
                <>
                  <td key="total-label" colSpan="4" className={styles.total} style={{ textAlign: 'center' }}>
                    {ligne.ligne_donnee[0]}
                  </td>
                  <td key="total-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                  <td key="total-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                </>
              );
            }

            if (ligne.ligne_donnee[0] === 'Total tous serveurs') {
              return (
                <>
                  <td key="total-servers-label" colSpan="1" className={styles.total} style={{ textAlign: 'center' }}>
                    {ligne.ligne_donnee[0]}
                  </td>
                  <td key="total-servers-qte" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[1]}</td>
                  <td key="total-servers-prix" style={{ textAlign: 'center' }}>{ligne.ligne_donnee[2]}</td>
                </>
              );
            }

            // Gestion des autres lignes de données
            return ligne.ligne_donnee.map((donnee, idx) => {
              if (idx === 1 && typeof donnee === 'string') { // Formatage de l'heure
                donnee = donnee.substring(0, 5);
              }

              if (idx === 5) { // Fusionner Prix et Type
                const prixTypeValue = `${donnee} ${ligne.ligne_donnee[6]}`;
                return <td key={idx} style={{ textAlign: 'center' }}>{prixTypeValue}</td>;
              }

              if (idx === 6) { // Ne pas afficher la colonne Type après fusion avec Prix
                return null;
              }

              if (idx === 7) {
                return null;
              }

              // Affichage normal pour les autres colonnes
              return <td key={idx} style={{ textAlign: 'center' }}>{donnee}</td>;
            });
          }
        }
      }
    } else {

      if (ligne.ligne_type == 'valeur') {

        return (ligne.ligne_donnee.map((donnee, index) =>
          <td id={index}>{donnee}</td>
        ))

      }

    }
    return null;
  };

  const renderTabAnnulation = (ligne, titreDonnees) => {
    // S'assurer que les données ne sont pas manquantes ou invalides
    if (!ligne || !ligne.ligne_donnee || ligne.ligne_donnee.length === 0) {
      return null; // Ne rien rendre si les données sont manquantes ou invalides
    }

    // Vérifier si 'rapports.cumul' est vrai et si la première donnée n'est pas 'Total'
    if (ligne.ligne_type === 'valeur') {
      if (rapports.cumul) {
        if (ligne.ligne_donnee[0] !== 'Total') {
          const valeursCombinees = [];
          valeursCombinees.push(ligne.ligne_donnee[0]); // Première valeur, le numéro et le serveur
          valeursCombinees.push(ligne.ligne_donnee[1]); // Deuxième valeur, le total C.A.
          for (let i = 2; i < ligne.ligne_donnee.length; i += 2) {
            valeursCombinees.push(`${ligne.ligne_donnee[i]} - ${ligne.ligne_donnee[i + 1]}`);
          }

          // Création du tableau avec les titres et les valeurs combinées
          return (
            <table className={styles.rapport4_table_individual}>
              <tbody>
                {titreDonnees.map((titre, index) => (
                  <tr key={index}>
                    <td className={styles.titreCell}>{titre}</td>
                    <td className={styles.valeurCell}>{valeursCombinees[index]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        }
      } else {
        if (ligne.ligne_donnee[0] !== 'Total') {
          // Si rapports.cumul est faux (ancien switch sans suppression de "Ratios")
          return (
            null
          );
        }
      }
    }
  }

  const openModal = (ticket) => {
    //console.log("Ouverture de la modale pour :", ticket);
    // Ici, cette fonction est appelée à chaque clic sur un ticket.
    // La gestion de la modale sera dans `TicketsComponent.js`
  };

  const renderTitle = () => {
    if (rapports.cumul == true) {
      return <h2><b>{rapportfinancier.rapport_nom}</b> <b>du</b> {cumul.date_min_affichage} <b>au</b> {cumul.date_max_affichage}</h2>
    } else {
      const date = format(Date.parse(service[0].date_service), 'EEEE dd MMMM yyyy', { locale: fr })
      return <h2><b>{rapportfinancier.rapport_nom}</b> Service n°{service[0].num_service} <b>du</b> {date}</h2>
    }
  }

  const renderNumtabres = () => {
    if (rapports.cumul == false && rapport.rapport_id == 5) {
      return <h2>BANDE CONTRÔLE N°{service[0].num_tab_res_affichage}</h2>
    }
  }

  const renderChargement = () => {
    // donc on n'affiche pas le spinner
    if (moisStatus && moisStatus.length > 0) {
      return null;
    }
    if (hasMore && hasLess && !pause) {
      return (
        <div className={styles.spinner}>
          <Spinner className={styles.spinner} variant="dark" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    }
  }

  const renderRapportFinancier = () => {
    switch (rapportfinancier.rapport_id) {
      case 1:
        return (
          <div ref={componentRef}>
            <div className={styles.rapport4_titre_section}>
              <div>{renderTitle()}</div>
              <div className={styles.numtabres_rapport4}>{renderNumtabres()}</div>
              <Button className={styles.bouton_imprimer_rapport4} variant="secondary" onClick={handlePrint}>
                Imprimer
              </Button>
            </div>

            {rapportfinancier.sections[0] && (
              <div className={styles.section_rapport4} key={rapportfinancier.sections[0].section_nom}>
                <h3 className={styles.group_title}>{rapportfinancier.sections[0].section_nom}</h3>
              </div>
            )}

            {/* Totaux par groupe */}
            <div className={styles.rapport4_table_wrapper}>
              <table className={styles.rapport4_table}>
                <thead>
                  {rapportfinancier.sections[0].tableaux[0].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderHeaderTotaux(ligne, isSmallScreen655, rapportfinancier.sections[0].tableaux[0].tableau_id)} {/* Header spécifique pour `rapport_id` 1 */}
                    </React.Fragment>
                  ))}
                </thead>
                <tbody>
                  {rapportfinancier.sections[0].tableaux[0].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderBodyTotaux(ligne, isSmallScreen655, rapportfinancier.sections[0].tableaux[0].tableau_id)} {/* Body spécifique pour `rapport_id` 1 */}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            {/* détails des encaissements Totaux*/}
            <div className={styles.rapport4_table_wrapper}>
              <table className={styles.rapport4_table}>
                <thead>
                  {rapportfinancier.sections[0].tableaux[1].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderHeaderTotaux(ligne, isSmallScreen655, rapportfinancier.sections[0].tableaux[1].tableau_id)} {/* Header du tableau */}
                    </React.Fragment>
                  ))}
                </thead>
                <tbody>
                  {rapportfinancier.sections[0].tableaux[1].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderBodyTotaux(ligne, isSmallScreen655, rapportfinancier.sections[0].tableaux[1].tableau_id)} {/* Body du tableau */}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Tableaux par Famille */}
            {rapportfinancier.sections.slice(1).map((section) => (
              <div className={styles.section_rapport4} key={section.section_nom}>
                <h3 className={styles.group_title}>{section.section_nom}</h3>
                <div className={styles.rapport4_table_wrapper}>
                  {section.tableaux.map((tableau, tableauIndex) => (
                    <div className={styles.rapport4_table} key={tableauIndex}>
                      <table className={styles.rapport4_table}>
                        <caption>{tableau.tableau_nom}</caption>
                        <thead>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024, tableauIndex)} {/* Header pour les tableaux de Famille */}
                            </React.Fragment>
                          ))}
                        </thead>
                        <tbody>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024, tableauIndex, rapportfinancier.sections[0].tableaux)} {/* Body pour les tableaux de Famille */}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
      case 4:
        return (
          <div ref={componentRef}>
            <div className={styles.rapport4_titre_section}>
              <div>{renderTitle()}</div>
              <div className={styles.numtabres_rapport4}>{renderNumtabres()}</div>
              <Button className={styles.bouton_imprimer_rapport4} variant="secondary" onClick={handlePrint}>
                Imprimer
              </Button>
            </div>
            {rapportfinancier.sections[0] && (
              <div className={styles.section_rapport4} key={rapportfinancier.sections[0].section_nom}>
                <h3 className={styles.group_title}>{rapportfinancier.sections[0].section_nom}</h3>
              </div>
            )}
            {/* Totaux par groupe */}
            <div className={styles.rapport4_table_wrapper}>
              <table className={styles.rapport4_table}>
                <thead>
                  {rapportfinancier.sections[0].tableaux[0].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderHeaderTotaux(ligne, isSmallScreen655)} {/* Header pour Totaux */}
                    </React.Fragment>
                  ))}
                </thead>
                <tbody>
                  {rapportfinancier.sections[0].tableaux[0].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderBodyTotaux(ligne, isSmallScreen655)} {/* Body pour Totaux */}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Tableaux par Famille */}
            {rapportfinancier.sections.slice(1).map((section) => (
              <div className={styles.section_rapport4} key={section.section_nom}>
                <h3 className={styles.group_title}>{section.section_nom}</h3>
                <div className={styles.rapport4_table_wrapper}>
                  {section.tableaux.map((tableau, tableauIndex) => (
                    <div className={styles.rapport4_table} key={tableauIndex}>
                      <table className={styles.rapport4_table}>
                        <caption>{tableau.tableau_nom}</caption>
                        <thead>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024)} {/* Header pour Famille */}
                            </React.Fragment>
                          ))}
                        </thead>
                        <tbody>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024)} {/* Body pour Famille */}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
      case 6:
        return (
          <div ref={componentRef}>
            <div className={styles.rapport4_titre_section}>
              <div>{renderTitle()}</div>
              <div className={styles.numtabres_rapport4}>{renderNumtabres()}</div>
              <Button className={styles.bouton_imprimer_rapport4} variant="secondary" onClick={handlePrint}>
                Imprimer
              </Button>
            </div>

            {rapportfinancier.sections.map((section) => (
              <div className={styles.section_rapport4} key={section.section_nom}>
                <h3 className={styles.group_title}>{section.section_nom}</h3>
                <div className={styles.rapport4_table_wrapper}>
                  {section.tableaux.map((tableau, tableauIndex) => (
                    <div className={styles.rapport4_table} key={tableauIndex}>
                      {isSmallScreen655 ?
                        tableau.lignes.map((ligne, index) => (
                          <div key={index} className={styles.rapport4_table_individual_wrapper}>
                            {renderTabAnnulation(ligne, titreDonnees)}
                          </div>
                        )) :
                        <table className={styles.rapport4_table}>
                          <tbody>
                            {tableau.lignes.map((ligne, index) => (
                              <React.Fragment key={index}>
                                {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024)}
                                {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024)}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      }
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );

      case 2:
        return (
          <div ref={componentRef}>
            <div className={styles.grille_titre}>
              <div>{renderTitle()}</div>
              <div className={styles.numtabres}>{renderNumtabres()}</div>
              <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>
                Imprimer
              </Button>
            </div>

            {rapportfinancier.sections.map((section) => (
              <div className={styles.section_titre} key={section.section_nom}>
                <div>
                  <div className={styles.annotationContainer}>
                    <div className={styles.annotationLabelTitre}><b>Facture:</b></div>
                    <div className={styles.annotationItem}>
                      <div className={styles.annotationLabel}>Avant factures :</div>
                      <div className={styles.annotationValue}>-</div>
                    </div>
                    <div className={styles.annotationItem}>
                      <div className={styles.annotationLabel}>Après factures :</div>
                      <div className={styles.annotationValue}>AF</div>
                    </div>
                    <div className={styles.annotationItem}>
                      <div className={styles.annotationLabel}>Après encaissements :</div>
                      <div className={styles.annotationValue}>AE</div>
                    </div>
                  </div>
                </div>
                <h3 className={styles.group_title}>{section.section_nom}</h3>
                <div className={styles.grille_tableau}>
                  {section.tableaux.map((tableau, tableauIndex) => {
                    // Condition pour filtrer: affiche tous les tableaux sauf le dernier seulement s'ils ont plus de deux lignes
                    const shouldDisplay = tableauIndex === section.tableaux.length - 1 || tableau.lignes.length > 2;
                    const isLastTable = tableauIndex === section.tableaux.length - 1; // Vérifie si c'est le dernier tableau

                    return shouldDisplay ? (
                      <div className={styles.tableau} key={tableau.tableau_nom}>
                        <table className={styles.rapport4_table}>
                          <caption>{tableau.tableau_nom}</caption>
                          <thead>
                            {tableau.lignes.some(ligne => ligne.ligne_type === 'titre') && !isLastTable ? (
                              tableau.lignes.map((ligne, index) =>
                                ligne.ligne_type === 'titre' ? (
                                  <React.Fragment key={index}>
                                    {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)}
                                  </React.Fragment>
                                ) : null
                              )
                            ) : (
                              // Header pour le dernier tableau
                              <tr style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                                {Array.from({ length: isSmallScreen600 ? 6 : isSmallScreen850 ? 6 : 7 }).map((_, idx) => (
                                  <th key={idx} style={{
                                    backgroundColor: '#262626',
                                    textAlign: 'center',
                                    color: '#262626',
                                  }}>Qte</th>
                                ))}
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {tableau.lignes.map((ligne, index) => (
                              <tr key={index}>
                                {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            ))}
          </div>
        );

      case 3:
      case 7:
        return (
          <div ref={componentRef}>
            <div className={styles.grille_titre}>
              <div>{renderTitle()}</div>
              <div className={styles.numtabres}>{renderNumtabres()}</div>
              <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>
                Imprimer
              </Button>
            </div>

            {rapportfinancier.sections.map((section) => (
              <div className={styles.section_titre} key={section.section_nom}>
                <div>
                  <div className={styles.annotationContainer}>
                    <div className={styles.annotationLabelTitre}><b>Facture:</b></div>
                    <div className={styles.annotationItem}>
                      <div className={styles.annotationLabel}>Avant factures :</div>
                      <div className={styles.annotationValue}>-</div>
                    </div>
                    <div className={styles.annotationItem}>
                      <div className={styles.annotationLabel}>Après factures :</div>
                      <div className={styles.annotationValue}>AF</div>
                    </div>
                    <div className={styles.annotationItem}>
                      <div className={styles.annotationLabel}>Après encaissements :</div>
                      <div className={styles.annotationValue}>AE</div>
                    </div>
                  </div>
                </div>
                <h3 className={styles.group_title}>{section.section_nom}</h3>
                <div className={styles.grille_tableau}>
                  {section.tableaux.map((tableau, tableauIndex) => {
                    // Condition pour filtrer: affiche tous les tableaux sauf le dernier seulement s'ils ont plus de deux lignes
                    const shouldDisplay = tableauIndex === section.tableaux.length - 1 || tableau.lignes.length > 2;
                    const isLastTable = tableauIndex === section.tableaux.length - 1; // Vérifie si c'est le dernier tableau

                    return shouldDisplay ? (
                      <div className={styles.tableau} key={tableau.tableau_nom}>
                        <table className={styles.rapport4_table}>
                          <caption>{tableau.tableau_nom}</caption>
                          <thead>
                            {tableau.lignes.some(ligne => ligne.ligne_type === 'titre') && !isLastTable ? (
                              tableau.lignes.map((ligne, index) =>
                                ligne.ligne_type === 'titre' ? (
                                  <React.Fragment key={index}>
                                    {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)}
                                  </React.Fragment>
                                ) : null
                              )
                            ) : (
                              // Header pour le dernier tableau
                              <tr style={{ backgroundColor: '#262626', textAlign: 'center' }}>
                                {Array.from({ length: isSmallScreen600 ? 4 : isSmallScreen850 ? 6 : 6 }).map((_, idx) => (
                                  <th key={idx} style={{
                                    backgroundColor: '#262626',
                                    textAlign: 'center',
                                    color: '#262626',
                                  }}>Qte</th>
                                ))}
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {tableau.lignes.map((ligne, index) => (
                              <tr key={index}>
                                {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            ))}
          </div>
        );
      default:
        // Cas par défaut : afficher un message ou une structure générique si rapport_id n'est pas 4
        return (
          <div ref={componentRef}>
            <div className={styles.grille_titre}>
              <div>{renderTitle()}</div>
              <div className={styles.numtabres}>{renderNumtabres()}</div>
              <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>
                Imprimer
              </Button>
            </div>

            {/* Sections et tableaux génériques */}
            {rapportfinancier.sections.map((section) => (
              <div className={styles.section_titre} key={section.section_nom}>
                <h3 className={styles.group_title}>{section.section_nom}</h3>
                <div className={styles.grille_tableau}>
                  {section.tableaux.map((tableau, tableauIndex) => (
                    <div className={styles.tableau} key={tableau.tableau_nom}>
                      <table className={styles.rapport4_table}>
                        <caption>{tableau.tableau_nom}</caption>
                        <thead>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)} {/* Header générique */}
                            </React.Fragment>
                          ))}
                        </thead>
                        <tbody>
                          {tableau.lignes.map((ligne, index) => (
                            <tr key={index}>
                              {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024, isSmallScreen600, isSmallScreen850)} {/* Body générique */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
    }
  };

  const renderBandeControleList = () => {
    switch (true) {
      case isSmallScreen500:
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            {/* Système de recherche */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
              <input
                  type="search"
                  placeholder="Rechercher ... (3 lettres min)"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    width: '300px',
                    fontSize: '14px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    padding: '8px',
                  }}
              />
            </div>

            {renderMoisProgressBar(moisStatus)}

            {/* Pagination en haut */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px', flexDirection: 'column' }}>
              <div>
                <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                  Premier
                </button>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                  Précédent
                </button>
              </div>
              <div>
                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .filter((page) => page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2))
                  .map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={page === currentPage ? 'active' : ''}
                      style={{
                        padding: '5px 10px',
                        backgroundColor: page === currentPage ? '#f40020' : '#b5b5b5',
                        color: page === currentPage ? '#fff' : '#000',
                        border: page === currentPage ? 'none' : '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    >
                      {page}
                    </button>
                  ))}
              </div>
              <div>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                  Suivant
                </button>
                <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                  Dernier
                </button>
              </div>
            </div>

            {/* Sélecteur pour le nombre de tickets par page */}
            <div style={{ marginTop: '-20px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
              <label htmlFor="ticketsPerPage">Tickets par page :</label>
              <select
                id="ticketsPerPage"
                value={ticketsPerPage}
                onChange={handleTicketsPerPageChange}
                style={{ padding: '5px', fontSize: '14px' }}
              >
                {[5, 10, 25, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            {/* Affichage des tickets */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column', // Une seule colonne
                gap: '10px', // Espacement vertical entre les tickets
                //maxWidth: '90%',
                width: '100%',
                margin: '0 auto', // Centrer l'ensemble horizontalement
                height: 'fit-content', // Ajustement automatique à la hauteur du contenu
              }}
            >
              {currentTickets.map((ticket, index) => (
                <div
                  key={index}
                  className={styles.document}
                  style={{
                    cursor: 'pointer',
                    background: '#e7e7e7',
                    border: '1px solid black',
                    textAlign: 'left',
                    padding: '10px', // Padding intérieur
                    margin: '0',
                    marginBottom: '10px', // Espacement entre les tickets
                    boxSizing: 'border-box',
                    width: '100%', // S'assurer que chaque ticket occupe toute la largeur disponible
                    maxWidth: '100%',
                  }}
                >
                  {/* En-tête */}
                  <div style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
                    {ticket.entete?.map((entete, i) => (
                      i === 1 ? (
                        <div key={i}>
                          <span>{entete}</span>
                          <span style={{ marginLeft: '8px' }}>{ticket.entete[i + 1]}</span>
                        </div>
                      ) : i === 2 ? null : (
                        <div key={i}>{entete}</div>
                      )
                    ))}
                  </div>
                  {/* Commandes */}
                  <div style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
                    {ticket.commande?.map((commande, i) => (
                      <div key={i} className={styles.document_commande}>
                        {commande.map((element, j) => (
                          <div key={j} style={{ textAlign: j === 2 ? 'right' : 'left' }}>{element}</div>
                        ))}
                      </div>
                    ))}
                  </div>
                  {/* Totaux */}
                  <div style={{ paddingBottom: '10px', width: '90%' }}>
                    {ticket.totaux?.map((total, i) => (
                      <div key={i} className={styles.document_totaux}>
                        {total.map((element, j) => (
                          <div key={j} style={{ textAlign: j === 1 ? 'right' : 'left' }}>{element}</div>
                        ))}
                      </div>
                    ))}
                  </div>
                  {/* Règlements */}
                  <div style={{ borderTop: '1px solid black', paddingTop: '10px', width: '90%' }}>
                    {ticket.reglement?.map((reglement, i) => (
                      <div key={i} className={styles.document_totaux}>
                        {reglement.map((element, j) => (
                          <div key={j} style={{ textAlign: j === 1 ? 'right' : 'left' }}>{element}</div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            {/* Sélecteur pour le nombre de tickets par page */}
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
              <label htmlFor="ticketsPerPage">Tickets par page :</label>
              <select
                id="ticketsPerPage"
                value={ticketsPerPage}
                onChange={handleTicketsPerPageChange}
                style={{ padding: '5px', fontSize: '14px' }}
              >
                {[5, 10, 25, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            {/* Pagination en bas */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px', flexDirection: 'column' }}>
              <div>
                <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                  Premier
                </button>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                  Précédent
                </button>
              </div>
              <div>
                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .filter((page) => page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2))
                  .map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={page === currentPage ? 'active' : ''}
                      style={{
                        padding: '5px 10px',
                        backgroundColor: page === currentPage ? '#f40020' : '#b5b5b5',
                        color: page === currentPage ? '#fff' : '#000',
                        border: page === currentPage ? 'none' : '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    >
                      {page}
                    </button>
                  ))}
              </div>
              <div>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                  Suivant
                </button>
                <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                  Dernier
                </button>
              </div>
            </div>
          </div>
        );
      case isSmallScreen600:
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            {/* Système de recherche */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
              <input
                  type="search"
                  placeholder="Rechercher ... (3 lettres min)"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    width: '300px',
                    fontSize: '14px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    padding: '8px',
                  }}
                />
            </div>

            {renderMoisProgressBar(moisStatus)}

            {/* Pagination en haut */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
              <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Premier
              </button>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter((page) => page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2))
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={page === currentPage ? 'active' : ''}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: page === currentPage ? '#f40020' : '#b5b5b5',
                      color: page === currentPage ? '#fff' : '#000',
                      border: page === currentPage ? 'none' : '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  >
                    {page}
                  </button>
                ))}
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Suivant
              </button>
              <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Dernier
              </button>
            </div>

            {/* Sélecteur pour le nombre de tickets par page */}
            <div style={{ marginTop: '-20px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
              <label htmlFor="ticketsPerPage">Tickets par page :</label>
              <select
                id="ticketsPerPage"
                value={ticketsPerPage}
                onChange={handleTicketsPerPageChange}
                style={{ padding: '5px', fontSize: '14px' }}
              >
                {[5, 10, 25, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            {/* Affichage des tickets */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column', // Une seule colonne
                gap: '10px', // Espacement vertical entre les tickets
                maxWidth: '90%',
                margin: '0 auto', // Centrer l'ensemble horizontalement
                height: 'fit-content', // Ajustement automatique à la hauteur du contenu
              }}
            >
              {currentTickets.map((ticket, index) => (
                <div
                  key={index}
                  className={styles.document}
                  style={{
                    cursor: 'pointer',
                    background: '#e7e7e7',
                    border: '1px solid black',
                    textAlign: 'left',
                    padding: '10px', // Padding intérieur
                    marginBottom: '10px', // Espacement entre les tickets
                    boxSizing: 'border-box',
                    width: '100%', // S'assurer que chaque ticket occupe toute la largeur disponible
                    margin: '0',
                  }}
                >
                  {/* En-tête */}
                  <div style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
                    {ticket.entete?.map((entete, i) => (
                      i === 1 ? (
                        <div key={i}>
                          <span>{entete}</span>
                          <span style={{ marginLeft: '8px' }}>{ticket.entete[i + 1]}</span>
                        </div>
                      ) : i === 2 ? null : (
                        <div key={i}>{entete}</div>
                      )
                    ))}
                  </div>
                  {/* Commandes */}
                  <div style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
                    {ticket.commande?.map((commande, i) => (
                      <div key={i} className={styles.document_commande}>
                        {commande.map((element, j) => (
                          <div key={j} style={{ textAlign: j === 2 ? 'right' : 'left' }}>{element}</div>
                        ))}
                      </div>
                    ))}
                  </div>
                  {/* Totaux */}
                  <div style={{ paddingBottom: '10px', width: '90%' }}>
                    {ticket.totaux?.map((total, i) => (
                      <div key={i} className={styles.document_totaux}>
                        {total.map((element, j) => (
                          <div key={j} style={{ textAlign: j === 1 ? 'right' : 'left' }}>{element}</div>
                        ))}
                      </div>
                    ))}
                  </div>
                  {/* Règlements */}
                  <div style={{ borderTop: '1px solid black', paddingTop: '10px', width: '90%' }}>
                    {ticket.reglement?.map((reglement, i) => (
                      <div key={i} className={styles.document_totaux}>
                        {reglement.map((element, j) => (
                          <div key={j} style={{ textAlign: j === 1 ? 'right' : 'left' }}>{element}</div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            {/* Sélecteur pour le nombre de tickets par page */}
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
              <label htmlFor="ticketsPerPage">Tickets par page :</label>
              <select
                id="ticketsPerPage"
                value={ticketsPerPage}
                onChange={handleTicketsPerPageChange}
                style={{ padding: '5px', fontSize: '14px' }}
              >
                {[5, 10, 25, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            {/* Pagination en bas */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', margin: '20px 0px' }}>
              <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Premier
              </button>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter((page) => page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2))
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={page === currentPage ? 'active' : ''}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: page === currentPage ? '#f40020' : '#b5b5b5',
                      color: page === currentPage ? '#fff' : '#000',
                      border: page === currentPage ? 'none' : '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  >
                    {page}
                  </button>
                ))}
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Suivant
              </button>
              <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Dernier
              </button>
            </div>
          </div>
        );
      case isSmallScreen850: // Affichage en display flex pour écrans < 850px
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            {/* Système de recherche */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
              <input
                  type="search"
                  placeholder="Rechercher ... (3 lettres min)"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    width: '300px',
                    fontSize: '14px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    padding: '8px',
                  }}
                />
            </div>

            {renderMoisProgressBar(moisStatus)}

            {/* Pagination en haut */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
              <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Premier
              </button>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter((page) => page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2))
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={page === currentPage ? 'active' : ''}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: page === currentPage ? '#f40020' : '#b5b5b5',
                      color: page === currentPage ? '#fff' : '#000',
                      border: page === currentPage ? 'none' : '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  >
                    {page}
                  </button>
                ))}
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Suivant
              </button>
              <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Dernier
              </button>
            </div>

            {/* Sélecteur pour le nombre de tickets par page */}
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
              <label htmlFor="ticketsPerPage">Tickets par page :</label>
              <select
                id="ticketsPerPage"
                value={ticketsPerPage}
                onChange={handleTicketsPerPageChange}
                style={{ padding: '5px', fontSize: '14px' }}
              >
                {[5, 10, 25, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            {/* Affichage des tickets */}
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between', // Espacement équilibré entre les colonnes
                gap: '15px', // Espacement horizontal entre les colonnes
                maxWidth: '90%',
                margin: '0 auto', // Centrer l'ensemble horizontalement
              }}
            >
              {Array(Math.ceil(currentTickets.length / 2)) // Divise en rangées de 2 tickets par colonne
                .fill()
                .map((_, rowIndex) => (
                  <div
                    key={rowIndex}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px', // Espacement vertical entre les tickets
                      flex: 1, // Permet aux colonnes d'occuper un espace équilibré
                      minWidth: '45%', // Largeur minimale des colonnes
                      alignItems: 'stretch', // Empêche l'étirement des tickets
                      height: 'fit-content',
                    }}
                  >
                    {currentTickets
                      .slice(rowIndex * 2, rowIndex * 2 + 2) // Sélectionne les tickets pour cette colonne
                      .map((ticket, index) => (
                        <div
                          key={index}
                          className={styles.document}
                          style={{
                            cursor: 'pointer',
                            background: '#e7e7e7',
                            border: '1px solid black',
                            textAlign: 'left',
                            padding: '10px', // Padding intérieur
                            marginBottom: '10px', // Espacement entre les tickets
                            boxSizing: 'border-box',
                            width: '100%', // Assure que chaque ticket occupe toute la largeur de la colonne
                            margin: '0',
                          }}
                        >
                          {/* En-tête */}
                          <div style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
                            {ticket.entete?.map((entete, i) => (
                              i === 1 ? (
                                <div key={i}>
                                  <span>{entete}</span>
                                  <span style={{ marginLeft: '8px' }}>{ticket.entete[i + 1]}</span>
                                </div>
                              ) : i === 2 ? null : (
                                <div key={i}>{entete}</div>
                              )
                            ))}
                          </div>
                          {/* Commandes */}
                          <div style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
                            {ticket.commande?.map((commande, i) => (
                              <div key={i} className={styles.document_commande}>
                                {commande.map((element, j) => (
                                  <div key={j} style={{ textAlign: j === 2 ? 'right' : 'left' }}>{element}</div>
                                ))}
                              </div>
                            ))}
                          </div>
                          {/* Totaux */}
                          <div style={{ paddingBottom: '10px', width: '90%' }}>
                            {ticket.totaux?.map((total, i) => (
                              <div key={i} className={styles.document_totaux}>
                                {total.map((element, j) => (
                                  <div key={j} style={{ textAlign: j === 1 ? 'right' : 'left' }}>{element}</div>
                                ))}
                              </div>
                            ))}
                          </div>
                          {/* Règlements */}
                          <div style={{ borderTop: '1px solid black', paddingTop: '10px', width: '90%' }}>
                            {ticket.reglement?.map((reglement, i) => (
                              <div key={i} className={styles.document_totaux}>
                                {reglement.map((element, j) => (
                                  <div key={j} style={{ textAlign: j === 1 ? 'right' : 'left' }}>{element}</div>
                                ))}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
            </div>

            {/* Sélecteur pour le nombre de tickets par page */}
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
              <label htmlFor="ticketsPerPage">Tickets par page :</label>
              <select
                id="ticketsPerPage"
                value={ticketsPerPage}
                onChange={handleTicketsPerPageChange}
                style={{ padding: '5px', fontSize: '14px' }}
              >
                {[5, 10, 25, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            {/* Pagination en bas */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', margin: '20px 0px' }}>
              <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Premier
              </button>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter((page) => page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2))
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={page === currentPage ? 'active' : ''}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: page === currentPage ? '#f40020' : '#b5b5b5',
                      color: page === currentPage ? '#fff' : '#000',
                      border: page === currentPage ? 'none' : '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  >
                    {page}
                  </button>
                ))}
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Suivant
              </button>
              <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Dernier
              </button>
            </div>
          </div>
        );
      case isSmallScreen1024:
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            {/* Système de recherche */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
              <input
                type="search"
                placeholder="Rechercher ... (3 lettres min)"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{
                  width: '300px',
                  fontSize: '14px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  padding: '8px',
                }}
              />
            </div>

            {renderMoisProgressBar(moisStatus)}

            {/* Pagination en haut */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
              <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Premier
              </button>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter((page) => page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2))
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={page === currentPage ? 'active' : ''}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: page === currentPage ? '#f40020' : '#b5b5b5',
                      color: page === currentPage ? '#fff' : '#000',
                      border: page === currentPage ? 'none' : '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  >
                    {page}
                  </button>
                ))}
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Suivant
              </button>
              <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Dernier
              </button>
            </div>

            {/* Sélecteur pour le nombre de tickets par page */}
            <div style={{ marginTop: '-20px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
              <label htmlFor="ticketsPerPage">Tickets par page :</label>
              <select
                id="ticketsPerPage"
                value={ticketsPerPage}
                onChange={handleTicketsPerPageChange}
                style={{ padding: '5px', fontSize: '14px' }}
              >
                {[5, 10, 25, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            {/* Affichage des tickets */}
            {currentTickets.map((ticket, ticketIndex) => {
              const totalCommandes = ticket.commande?.length || 0;

              // Logique pour les colonnes en fonction du nombre de commandes
              const commandesCol1 =
                totalCommandes > 12
                  ? ticket.commande.slice(0, Math.ceil(totalCommandes / 2)) // Diviser en deux colonnes équilibrées si > 12
                  : ticket.commande.slice(0, 6); // Maximum de 8 articles dans la colonne 1 si <= 12

              const commandesCol2 =
                totalCommandes > 12
                  ? ticket.commande.slice(Math.ceil(totalCommandes / 2)) // Deuxième moitié si > 12
                  : ticket.commande.slice(6); // Reste dans la colonne 2 si <= 12

              return (
                <div
                  key={ticketIndex}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: '10px',
                    marginBottom: '15px',
                    border: '1px solid black',
                    padding: '10px',
                    width: '100%',
                  }}
                >
                  {/* En-tête */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      borderBottom: '1px solid black',
                      padding: '0px 0px 5px 0px',
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ flex: 1, textAlign: 'center' }}>{ticket.entete[0]}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          justifyContent: 'space-evenly',
                          width: '35%',
                        }}
                      >
                        <div style={{ flex: 1, textAlign: 'center' }}>{ticket.entete[1]}</div>
                        <div style={{ flex: 1, textAlign: 'center' }}>{ticket.entete[2]}</div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          justifyContent: 'space-evenly',
                          width: '65%',
                        }}
                      >
                        <div style={{ flex: 1, textAlign: 'center' }}>{ticket.entete[3]}</div>
                        <div style={{ flex: 1, textAlign: 'center' }}>{ticket.entete[4]}</div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr 1fr',
                      gap: '10px',
                    }}
                  >
                    {/* Totaux */}
                    <div style={{ padding: '10px', borderRight: '1px solid black' }}>
                      {ticket.totaux?.map((total, i) => (
                        <div
                          key={i}
                          className={styles.document_totaux}
                          style={{
                            borderBottom:
                              i === ticket.totaux.length - 1 ? 'none' : '1px solid #ccc',
                            paddingBottom: '5px',
                            marginBottom: '5px',
                          }}
                        >
                          {total.map((element, j) => (
                            <div
                              key={j}
                              style={{
                                textAlign: j === 1 ? 'right' : 'left',
                                width: j === 1 ? 'auto' : 'max-content'
                              }}
                            >
                              {element}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>

                    {/* Règlements */}
                    <div style={{ padding: '10px', borderRight: '1px solid black' }}>
                      {ticket.reglement?.map((reglement, i) => (
                        <div
                          key={i}
                          className={styles.document_totaux}
                          style={{
                            borderBottom:
                              i === ticket.reglement.length - 1
                                ? 'none'
                                : '1px solid #ccc',
                            paddingBottom: '5px',
                            marginBottom: '5px',
                          }}
                        >
                          {reglement.map((element, j) => (
                            <div
                              key={j}
                              style={{
                                textAlign: j === 1 ? 'right' : 'left',
                              }}
                            >
                              {element}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>

                    {/* Commandes colonne 1 */}
                    <div style={{ padding: '10px', borderRight: '1px solid black' }}>
                      {commandesCol1.map((commande, i) => (
                        <div
                          key={i}
                          className={styles.document_commande}
                          style={{
                            borderBottom:
                              i === commandesCol1.length - 1
                                ? 'none'
                                : '1px solid #ccc',
                            paddingBottom: '5px',
                            marginBottom: '5px',
                          }}
                        >
                          {commande.map((element, j) => (
                            <div
                              key={j}
                              style={{
                                textAlign: j === 2 ? 'right' : 'left',
                              }}
                            >
                              {element}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>

                    {/* Commandes colonne 2 */}
                    <div style={{ padding: '10px' }}>
                      {commandesCol2.map((commande, i) => (
                        <div
                          key={i}
                          className={styles.document_commande}
                          style={{
                            borderBottom:
                              i === commandesCol2.length - 1
                                ? 'none'
                                : '1px solid #ccc',
                            paddingBottom: '5px',
                            marginBottom: '5px',
                          }}
                        >
                          {commande.map((element, j) => (
                            <div
                              key={j}
                              style={{
                                textAlign: j === 2 ? 'right' : 'left',
                              }}
                            >
                              {element}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}

            {/* Sélecteur pour le nombre de tickets par page */}
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
              <label htmlFor="ticketsPerPage">Tickets par page :</label>
              <select
                id="ticketsPerPage"
                value={ticketsPerPage}
                onChange={handleTicketsPerPageChange}
                style={{ padding: '5px', fontSize: '14px' }}
              >
                {[5, 10, 25, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            {/* Pagination en bas */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', margin: '20px 0px' }}>
              <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Premier
              </button>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter((page) => page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2))
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={page === currentPage ? 'active' : ''}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: page === currentPage ? '#f40020' : '#b5b5b5',
                      color: page === currentPage ? '#fff' : '#000',
                      border: page === currentPage ? 'none' : '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  >
                    {page}
                  </button>
                ))}
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Suivant
              </button>
              <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Dernier
              </button>
            </div>
          </div>
        );
      default:
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            {/* Système de recherche */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
              <input
                type="search"
                placeholder="Rechercher ... (3 lettres min)"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{
                  width: '300px',
                  fontSize: '14px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  padding: '8px',
                }}
              />
            </div>

            {renderMoisProgressBar(moisStatus)}

            {/* Pagination en haut */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
              <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Premier
              </button>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter((page) => page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2))
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={page === currentPage ? 'active' : ''}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: page === currentPage ? '#f40020' : '#b5b5b5',
                      color: page === currentPage ? '#fff' : '#000',
                      border: page === currentPage ? 'none' : '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  >
                    {page}
                  </button>
                ))}
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Suivant
              </button>
              <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Dernier
              </button>
            </div>

            {/* Sélecteur pour le nombre de tickets par page */}
            <div style={{ marginTop: '-20px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
              <label htmlFor="ticketsPerPage">Tickets par page :</label>
              <select
                id="ticketsPerPage"
                value={ticketsPerPage}
                onChange={handleTicketsPerPageChange}
                style={{ padding: '5px', fontSize: '14px' }}
              >
                {[5, 10, 25, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            {/* Affichage des tickets */}
            {currentTickets.map((ticket, ticketIndex) => {
              const totalCommandes = ticket.commande?.length || 0;

              const commandesCol1 =
                totalCommandes > 14
                  ? ticket.commande.slice(0, Math.ceil(totalCommandes / 2))
                  : ticket.commande.slice(0, 7);

              const commandesCol2 =
                totalCommandes > 14
                  ? ticket.commande.slice(Math.ceil(totalCommandes / 2))
                  : ticket.commande.slice(7);

              return (
                <div
                  key={ticketIndex}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                    gap: '0',
                    marginBottom: '15px',
                    border: '1px solid black',
                    padding: '10px',
                    width: '100%',
                    boxShadow: '3px 4px 6px -3px rgba(0,0,0,0.7)',
                  }}
                >
                  {/* En-tête */}
                  <div style={{ borderRight: '1px solid black', padding: '10px' }}>
                    {ticket.entete?.map((entete, i) => (
                      i === 1 ? (
                        <div key={i}>
                          <span>{entete}</span>
                          <span style={{ marginLeft: '8px' }}>{ticket.entete[i + 1]}</span>
                        </div>
                      ) : i === 2 ? null : (
                        <div key={i}>{entete}</div>
                      )
                    ))}
                  </div>

                  {/* Totaux */}
                  <div style={{ borderRight: '1px solid black', padding: '10px' }}>
                    {ticket.totaux?.map((total, i) => (
                      <div
                        key={i}
                        className={styles.document_totaux}
                        style={{
                          borderBottom: i === ticket.totaux.length - 1 ? 'none' : '1px solid #ccc',
                          paddingBottom: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        {total.map((element, j) => (
                          <div
                            key={j}
                            style={{
                              textAlign: j === 1 ? 'right' : 'left',
                              width: j === 1 ? 'auto' : 'max-content',
                            }}
                          >
                            {element}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  {/* Règlements */}
                  <div style={{ padding: '10px' }}>
                    {ticket.reglement?.map((reglement, i) => (
                      <div
                        key={i}
                        className={styles.document_totaux}
                        style={{
                          borderBottom: i === ticket.reglement.length - 1 ? 'none' : '1px solid #ccc',
                          paddingBottom: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        {reglement.map((element, j) => (
                          <div
                            key={j}
                            style={{
                              textAlign: j === 1 ? 'right' : 'left',
                              width: j === 1 ? 'auto' : 'max-content',
                            }}
                          >
                            {element}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  {/* Commandes colonne 1 */}
                  <div style={{ borderLeft: '1px solid black', padding: '10px' }}>
                    {commandesCol1.map((commande, i) => (
                      <div
                        key={i}
                        className={styles.document_commande}
                        style={{
                          borderBottom: i === commandesCol1.length - 1 ? 'none' : '1px solid #ccc',
                          paddingBottom: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        {commande.map((element, j) => (
                          <div key={j} style={{ textAlign: j === 2 ? 'right' : 'left' }}>{element}</div>
                        ))}
                      </div>
                    ))}
                  </div>

                  {/* Commandes colonne 2 */}
                  <div style={{ padding: '10px 10px 10px 30px', borderLeft: '1px solid #ccc' }}>
                    {commandesCol2.map((commande, i) => (
                      <div
                        key={i}
                        className={styles.document_commande}
                        style={{
                          borderBottom: i === commandesCol2.length - 1 ? 'none' : '1px solid #ccc',
                          paddingBottom: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        {commande.map((element, j) => (
                          <div key={j} style={{ textAlign: j === 2 ? 'right' : 'left' }}>{element}</div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}

            {/* Sélecteur pour le nombre de tickets par page */}
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
              <label htmlFor="ticketsPerPage">Tickets par page :</label>
              <select
                id="ticketsPerPage"
                value={ticketsPerPage}
                onChange={handleTicketsPerPageChange}
                style={{ padding: '5px', fontSize: '14px' }}
              >
                {[5, 10, 25, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            {/* Pagination en bas */}
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', margin: '20px 0px' }}>
              <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Premier
              </button>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ padding: '5px 10px' }}>
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter((page) => page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2))
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={page === currentPage ? 'active' : ''}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: page === currentPage ? '#f40020' : '#b5b5b5',
                      color: page === currentPage ? '#fff' : '#000',
                      border: page === currentPage ? 'none' : '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  >
                    {page}
                  </button>
                ))}
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Suivant
              </button>
              <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} style={{ padding: '5px 10px' }}>
                Dernier
              </button>
            </div>
          </div>
        );
    }
  }

  const renderBandeControle = () => {
    return (
      <div ref={componentRef} >
        <div className={styles.grille_titre}>
          <div>{renderTitle()}</div>
          {renderChargement()}
          <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>Imprimer</Button>
        </div>
        {renderBandeControleList()}
      </div>
    )
  }
  //{renderEtatChargement()}

  const renderRapport = () => {
    // 1) Si on est en bande de contrôle cumul (rapport_id=5 et cumul=true),
    // on ignore l'état "loading" et on affiche directement la bande de contrôle.
    if (rapportfinancier.rapport_id === 5 && cumul) {
      // On gère quand même les erreurs globales
      if (hasErrors) {
        return (
          <div className={styles.rapportAlertWrapper}>
            <Alert variant='danger'>Impossible de charger le rapport</Alert>
          </div>
        );
      }
      // Ou l'absence totale de données
      if (donnee === false) {
        return (
          <div className={styles.rapportAlertWrapper}>
            <Alert variant='warning'>Aucune donnée dans la période sélectionnée</Alert>
          </div>
        );
      }
      // Sinon, on affiche directement la bande de contrôle
      return renderBandeControle();
    }
  
    // 2) Sinon, on applique la logique "classique"
    //    - loading => bandeau de chargement
    //    - hasErrors => message d'erreur
    //    - lastEmptyMonth => message d'info
    //    - donnee === false => "Aucune donnée"
    //    - loaded => on affiche le rapport
    if (loading) {
      return (
        <div className={styles.rapportAlertWrapper}>
          <Alert variant='secondary'>Chargement du rapport</Alert>
        </div>
      );
    }
    if (hasErrors) {
      return (
        <div className={styles.rapportAlertWrapper}>
          <Alert variant='danger'>Impossible de charger le rapport</Alert>
        </div>
      );
    }
    if (lastEmptyMonth && nextMonthToLoad) {
      return (
        <div className={styles.rapportAlertWrapper}>
          <Alert variant="info">
            Aucune donnée dans la période du {lastEmptyMonth.date_min} au {lastEmptyMonth.date_max}.
            <br />
            Chargement en cours pour la période du {nextMonthToLoad.date_min} au {nextMonthToLoad.date_max}...
          </Alert>
        </div>
      );
    } else if (lastEmptyMonth && !nextMonthToLoad) {
      return (
        <div className={styles.rapportAlertWrapper}>
          <Alert variant="info">
            Aucune donnée dans la période du {lastEmptyMonth.date_min} au {lastEmptyMonth.date_max}.
            <br />
            (C'était le dernier mois, on attend la suite ou on a terminé.)
          </Alert>
        </div>
      );
    }
    if (donnee === false) {
      return (
        <div className={styles.rapportAlertWrapper}>
          <Alert variant='warning'>Aucune donnée dans la période sélectionnée</Alert>
        </div>
      );
    }
    if (loaded) {
      return (
        <div>
          {rapportfinancier.rapport_id === 5
            ? renderBandeControle()
            : renderRapportFinancier()}
        </div>
      );
    }
    // Par défaut, rien
    return null;
  };  

  return (
    <div className={styles.rapport_wrapper}>
      <div className={styles.div_rapport_list}>
        <div className={styles.rapport_list}>
          {renderRapportList()}
        </div>
      </div>
      <div className={styles.div_rapport}>
        {renderRapport()}
      </div>
    </div>
  )
}